import React, { useState, useEffect } from 'react'
import { call } from '../Methods'

function PrivacyPolicy () {
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(true)
  const contentKey = 'Page.privacypolicy'

  const getData = async () => {
    setLoading(true)
    const _privacyPolicyPage = await call.content.getContents({
      fullName: contentKey
    })
    setContent(_privacyPolicyPage)
    setLoading(false)
  }
  useEffect(() => {
    getData()
  }, [])

  if (loading) return <div className='loading'>Loading...</div>
  if (!content) return <div className='loading'>Something wrong happen...</div>
  return (
    <>
      <div className='page privacy-policy-page'>
        <div className='page-content text-content'>
          <div className='page-header'>
            <h1 className='main'>Privacy Policy</h1>
          </div>

          <p
            className='paragraph'
            dangerouslySetInnerHTML={{ __html: content.value }}
          ></p>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
