import React, { useEffect,useState} from 'react'
import _AdminSidebar from './_Components/AdminSidebar'
import { Outlet, useNavigate } from 'react-router-dom'
import { use } from '../Helpers'

const AdminLayout = () => {

    const [hasKey,setHasKey] = useState(false);

    const navigate = useNavigate();

    
    async function check(){
        let hasKey = await use.entryKey()
        if(!hasKey){
            navigate('/login', {replace:true})
            return;
        }
        setHasKey(hasKey);
    }

    useEffect(()=>{
        check();
    },[])


  return (
    hasKey && <React.Fragment>
      <div className='admin-pages'>
        <_AdminSidebar />
        <div className='admin-page'>
          <div className='page-content'>
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AdminLayout
