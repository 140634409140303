import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Table from '../_Components/AdminTable'
import { Link } from 'react-router-dom'
import { call } from '../../Methods'

function _Users () {
  const [tableData, setTableData] = useState([])

  const defaultParameters = {
    statusId: '',
    pageNumber: 1,
    pageSize: 10,
    sort: 'desc'
  }

  const userDefinedParameters = JSON.parse(
    localStorage.getItem('tpp_userSettings')
  ).searchSettings
  let _parameters = {}
  Object.assign(
    _parameters,
    defaultParameters,
    userDefinedParameters.searchSettings
  )
  const [parameters, setParameters] = useState(_parameters)

  let colorsByValues = {
    '-3': '#ff5252',
    '-2': 'black',
    '-1': '#c4c4c4',
    0: '#FF9800',
    1: '#4242c4',
    2: '#c442c4',
    3: '#42c442',
    4: '#c4c442',
    5: '#42c490'
  }

  
  let tableColumns = [
    {
      title: 'Email',
      property: 'email'
    },
    {
      title: 'Email',
      property: ['name','surname','phone','address'],
      mutate: values => {
        return (
          <div style={{textAlign: 'left'}}>
            <div>{values[0]} {values[1]}</div>
            <div><b>Phone:</b> {values[2]}, <b>Address:</b> {values[3]}</div>
          </div>
        )
      }
    },
    {
      title: 'Role',
      property: 'roleName',
      mutate:value=>{
        return (value=='admin'? <span><b>{value}</b></span>: <span>{value}</span>)
      }
    },
    {
      title: 'Status',
      property: ['statusId','statusName'],
      type: 'label',
      mutate: values => {
        return (
          <span style={{ backgroundColor: colorsByValues[values[0]] }}>
            {values[1]}
          </span>
        )
      }
    },
    {
      title: 'Actions',
      property: 'id',
      type: 'actions-list',
      mutate: value => {
        return (
          <>
            <Link to={`/admin/user/${value}`}>
              <img
                className='action-icon'
                title={'Edit'}
                src={'/Icons/edit.png'}
              />
            </Link>
          </>
        )
      }
    }
  ]

  const getData = async parameters => {
    const _users = await call.forLoggedUser.user.getUsers({})
    if(_users.success){
      setTableData(_users);
    }
    return _users
  }

  useEffect(() => {
    getData(parameters)
  }, [parameters])

  return (
    <React.Fragment>
      <h3 className='page-title'>Users</h3>
      {tableData && tableData.data && <Table
        parameters={parameters}
        columns={tableColumns}
        loading={tableData?false:true}
        data={tableData.data}
        addNew={{ enabled: true, url: '/admin/user/add' }}
      />}
    </React.Fragment>
  )
}

export default _Users
