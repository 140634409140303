import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Input from '../_Components/Input'
import Variables from '../../global';
import { call } from '../../Methods';
import {use} from '../../Helpers.js' 


function _PartnerEdit () {
  const { id } = useParams()
  const navigate = useNavigate()
  const [partner, setPartner] = useState()
  const [loading, setLoading] = useState(true)

  let colorsByValues = {
    '-3': '#ff5252',
    '-2': 'black',
    '-1': '#c4c4c4',
    0: '#FF9800',
    1: '#4242c4',
    2: '#c442c4',
    3: '#42c442',
    4: '#c4c442',
    5: '#42c490'
  }

  const loadData = async() =>{
    setLoading(true)
    const _partner = await call.forLoggedUser.partner.getPartner(id);

    if(_partner.id){
      setPartner(_partner)
    }else{
      navigate('/admin/partners',{replace:true})
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData();
  }, [])

  const saveData = async statusId => {
    setLoading(true);

    let image;

    let uploaded_image = document.querySelector(
      'img[name="input-image-preview"]'
    )

    if(uploaded_image){
      image = new Object({
        base64:   uploaded_image.dataset['base64'] || null,
        isNew:    uploaded_image.dataset['isnew']=== "true" ? true : false,
        imageUrl: uploaded_image.dataset['imageurl']
      })
    }    

    let _partner = {}
    _partner.id = +id;
    _partner.name = use.getInputValueByName('name');
    _partner.description = use.getInputValueByName('description');
    _partner.image = image;
    _partner.statusId = statusId;
    setPartner(_partner);

    const _partnerToUpdate = await call.forLoggedUser.partner.updatePartner(_partner);
    
    if (_partnerToUpdate.id) {
      setPartner(_partnerToUpdate)
      setLoading(false)
    }
  }

  return (
    !loading && (
      <React.Fragment>
        <form className='form'>
          <h4 className='form-title'>{partner.name}
          <span
              style={{
                marginLeft: '20px',
                backgroundColor: colorsByValues[partner.statusId]
              }}
              className='status-label'
            >
              {partner.statusName}
            </span>
          </h4>
          <div className='row'>
            <Input
              type={'text'}
              label={'Name'}
              fieldName={'name'}
              col={{ default: 12, md: 4 }}
              value={partner.name}
            />

            <Input
              type={'text'}
              label={'Description'}
              fieldName={'description'}
              col={{ default: 12, sm: 12, md: 4 }}
              value={partner.description}
            />

              <Input
              type={'image'}
              label={'Image'}
              fieldName={'images'}
              col={{ default: 12, sm: 12, md: 12 }}
              value={partner.image.imageurl}
              multiple={false}
              data={
                partner.image.imageUrl?[new Object({
                      src: `${Variables.base_url}/api/images/thumbnail-md/${partner.image.imageUrl}`,
                      srclarge:`${Variables.base_url}/api/images/large/${partner.image.imageUrl}`,
                      data: null,
                      isNew: false,
                      imageurl: partner.image.imageUrl
                    })]:[]
                  }
              eventHandler={{
                type: 'change',
                handler: function (e) {
                  let target = e.target
                }
              }}
            />
          </div>

          <div className='action-buttons'>
            <button
              type='button'
              className='back-button'
              onClick={function () {
                navigate('/admin/partners', { replace: true })
              }}
            >
              Back
            </button>
            <button
              type='button'
              className='save-button'
              onClick={function () {
                saveData(partner.statusId)
              }}
            >
              Save
            </button>
            {partner.statusId===1 && <button
              type='button'
              className='draft-button'
              onClick={function () {
                saveData(0)
              }}
            >
              Save & Unpublish
            </button>}
            {partner.statusId===0 && <button
              type='button'
              className='publish-button'
              onClick={function () {
                saveData(1)
              }}
            >
              Save & Publish
            </button>}
            <button
              type='button'
              className='delete-button'
              onClick={()=>function () {
                let confirmed = window.confirm('Are you sure to delete the partner')
                if(confirmed){
                    saveData(-1);
                    navigate('/admin/partners', { replace: true })
                } 
              }}
            >
              Delete
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  )
}

export default _PartnerEdit
