import { call } from './Methods'

export const use = {
  precisionRound: (number, precision) => {
    var factor = Math.pow(10, precision)
    return Math.round(number * factor) / factor
  },
  token: () => {
    return localStorage.getItem('tpp_token')
  },
  entryKey: async () => {
    let result = await call.user.checkToken(use.token())

    return use.token() ? result?.success : false
  },
  redirectToDashboard: async () => {},
  normalizePrecision:(number)=>{
    return Math.round(number*100)/100;
  },
  userSettings: () => {
    return JSON.parse(localStorage.getItem('tpp_userSettings'))
  },
  userShort: async () => {
    let userInfo = JSON.parse(sessionStorage.getItem('tpp_userInfo'))
    if (!userInfo) {
      let token = use.token()
      if (token) {
        const _userInfoRequest = await call.user.checkToken(token)
        if (_userInfoRequest.success) {
          userInfo = _userInfoRequest.data
        }
      }
    }
    return userInfo
  },
  getInputValueByName: (name, parent = document) => {
    console.log(name,parent)
    let element = parent.querySelectorAll(`body [name="${name}"]`)
    if (element.length === 1) {
      switch (element[0].type) {
        case 'checkbox':
          return Number(element[0].checked)
          break
        default:
          return element[0].value || element[0].text
      }
    } else if (element.length > 1) {
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  },
  toggleFormInput: formId => {},
  resetUserLoginInfo: () => {
    localStorage.removeItem('tpp_token')
    sessionStorage.removeItem('tpp_userInfo')
  },
  statusColors: () => {
    let colors = {};
    colors.forOrders = {
      '-2': '#ff5252',
      '-1': '#fcba03',
      0: '#FF9800',
      1: '#4242c4',
      2: '#c442c4',
      3: '#42c442',
      4: '#c4c442',
      5: '#42c490',
      6: '#42c4c4',
      7: '#ff5252',
      8: '#fcba03',
      9: '#ff9800'
    };
    return colors;
  }
}
