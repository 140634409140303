import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

function Cart ({}) {
  const cart = useSelector(state => state.cart.items)
  const [itemCount, setItemCount] = useState(0)

  useEffect(() => {}, [])

  return (
    <React.Fragment>
      <div className='cart'>
        <img className="cart-icon" src="./Icons/bag.svg"/>
        {cart && cart.length > 0 && (
          <span className='cart-item-count'>{cart.length}</span>
        )}
      </div>
    </React.Fragment>
  )
}

export default Cart
