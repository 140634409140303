import React, { Fragment, useEffect, useState } from 'react'
import ValidateInput from '../ValidationDefaults'
import { call } from '../Methods'
import Input from '../Admin/_Components/Input'
import { Link, Navigate, useNavigate } from 'react-router-dom'

export default function Register () {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthDate: '',
    password: '',
    reenteredPassword: ''
  })

  const ValidateAs = {
    firstName: ['required', 'firstName'],
    lastName: ['required', 'lastName'],
    email: ['required', 'email'],
    address: ['required'],
    phone:['required'],
    city: ['required'],
    postalCode:['required','CanadaPostCode'],
    password: ['required'],
    reenteredPassword: ['required']
  }

  let _formValues = {
    firstName: '',
    lastName: '',
    companyName: '',
    phone: '',
    city: '',
    address:'',
    postalCode:'',
    email: '',
    password: '',
    reenteredPassword: ''
  }

  const sendData = async e => {
      setLoading(true)
      e.preventDefault()
      let validationResults = [];
      [...e.target.elements].forEach(input => {
        if(input.type==='submit'){
          return;//continue
        }
        _formValues[input.name] = input.value
        if (ValidateAs[input.name]) {
          let errorSpan = document.querySelector(
            `span.warning-message[for=${input.name}]`
          )
          
          let validation = ValidateInput(
            input.name,
            ValidateAs[input.name],
            input.value
          )
          validationResults.push(validation)

          if (!validation.result) {
            errorSpan.classList.add('show')
            errorSpan.innerText = validation?.message
          }
        }
      })

      let validationResult = validationResults.every(r => r.result === true)

      if (validationResult) {
        const _result = await call.user.register(_formValues);
        console.log(_result)
        if (_result.success) {
          alert('Success');
          navigate('/login', {replace:true})
        }else{
          alert(_result.error.message);
        }
      }
      setLoading(false)
  }

  return (
    <div className='page account-page'>
       <img className="svg-background" src="/Backgrounds/wave-haikei.svg"/>
       <div className="page-content">
       <div className='account-forms'>
        <div className='form form-container registration-form'>
          {loading && <div className='form-loading'>Loading...</div>}
          <div className="form-header">
            <h1 className="main">Sign Up for Exclusive Signage Savings!</h1>
            <p className='sub'>
            Unlock premium deals and be the first to know about our latest
            signage releases. Register now to access exclusive discounts,
            personalized recommendations, and insider updates. Elevate your
            space with standout signage, tailored just for you!
          </p>
          </div>
          <form
            className='form row'
            method='post'
            onSubmit={e => sendData(e)}
            noValidate
            autoComplete='off'
          >
            <Input
              label={'First Name'}
              fieldName={'firstName'}
              type={'text'}
              col={{ default: 12, md: 6 }}
              required={true}
              eventHandler={[
                {
                  type: 'input',
                  handler: e => {
                    setFormValues(_formValues => ({
                      ..._formValues,
                      firstName: e.target.value
                    }))
                  }
                }
              ]}
            />
            <Input
              label={'Last Name'}
              fieldName={'lastName'}
              type={'text'}
              col={{ default: 12, md: 6 }}
              required={true}
              eventHandler={[
                {
                  type: 'input',
                  handler: e => {
                    setFormValues({ ...formValues, lastName: e.target.value })
                  }
                }
              ]}
            />
            <Input
              label={'Company Name'}
              type={'text'}
              fieldName={'companyName'}
              col={{ default: 12, md: 6 }}
              eventHandler={[
                {
                  type: 'input',
                  handler: e => {
                    setFormValues({ ...formValues, companyName: e.target.value });
                  }
                }
              ]}
            />
            <Input
              label={'Phone Number'}
              fieldName={'phone'}
              type={'text'}
              col={{ default: 12, md: 6 }}
              required={true}
              eventHandler={[
                {
                  type: 'input',
                  handler: e => {
                    setFormValues({ ...formValues, phone: e.target.value })
                  }
                }
              ]}
            />
            <Input
              label={'City'}
              fieldName={'city'}
              type={'text'}
              col={{ default: 12, md: 6 }}
              required={true}
              eventHandler={[
                {
                  type: 'input',
                  handler: e => {
                    setFormValues({ ...formValues, city: e.target.value })
                  }
                }
              ]}
            />
            <Input
              label={'Address'}
              fieldName={'address'}
              type={'text'}
              col={{ default: 12, md: 6 }}
              required={true}
              eventHandler={[
                {
                  type: 'input',
                  handler: e => {
                    setFormValues({ ...formValues, address: e.target.value })
                  }
                }
              ]}
            />
            <Input
              label={'Postal Code'}
              fieldName={'postalCode'}
              type={'text'}
              col={{ default: 12, md: 6 }}
              required={true}
              eventHandler={[
                {
                  type: 'input',
                  handler: e => {
                    setFormValues({ ...formValues, postalCode: e.target.value })
                  }
                }
              ]}
            />
            <Input
              label={'E-mail'}
              fieldName={'email'}
              type={'email'}
              col={{ default: 12, md: 6 }}
              required={true}
              eventHandler={[
                {
                  type: 'input',
                  handler: e => {
                    setFormValues({ ...formValues, email: e.target.value })
                  }
                }
              ]}
            />
            <Input
              label={'Password'}
              fieldName={'password'}
              type={'password'}
              required={true}
              col={{ default: 12, md: 6 }}
              eventHandler={[
                {
                  type: 'input',
                  handler: e => {
                    setFormValues({ ...formValues, password: e.target.value })
                  }
                }
              ]}
            />
            <Input
              label={'Please, re-type your password'}
              fieldName={'reenteredPassword'}
              type={'password'}
              required={true}
              col={{ default: 12, md: 6 }}
              eventHandler={[
                {
                  type: 'input',
                  handler: e => {
                    setFormValues({
                      ...formValues,
                      reenteredPassword: e.target.value
                    })
                  }
                }
              ]}
            />

            <div className='button-box'>
              <button className='button button-full-width' type='submit'>
                Register
              </button>
            </div>
            <span id="sign-in">Do you have one? Then <Link to='/login' >sign in</Link></span>
          </form>
        </div>
      </div>
       </div>

    </div>
  )
}
