import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Input from '../_Components/Input'
import { use } from '../../Helpers'
import { useDispatch } from 'react-redux'
import { updateDashboardData } from '../../Store/dashboardSlice'
import { call } from '../../Methods'

function _OrderAdd () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [order, setOrder] = useState()
  const [orderItems, setOrderItems] = useState()
  const [countries, setCountries] = useState([])
  const [sizes, setSizes] = useState([])
  const [substrates, setSubstrates] = useState([])
  const [statuses, setStatuses] = useState([])
  const [alertBoxParameters, setAlertBoxParameters] = useState({
    active: false,
    type: '',
    message: ''
  })
  const [loading, setLoading] = useState(false)

  let colorsByValues = {
    '-3': '#ff5252',
    '-2': 'black',
    '-1': '#c4c4c4',
    0: '#FF9800',
    1: '#4242c4',
    2: '#c442c4',
    3: '#42c442',
    4: '#c4c442',
    5: '#42c490'
  }

  async function getData () {
    setLoading(true)
    const _countries = await call.forLoggedUser.country.getCountryList({})
    if (_countries.success) {
    }
    setCountries(
      _countries.data.map(({ id, name }) => {
        return { value: id, text: name }
      })
    )

    const _statuses = await call.forLoggedUser.statuses.getStatuses({
      type: 'Order'
    })
    if (_statuses?.success) {
      setStatuses(_statuses.data)
    }

    const _sizes = await call.forLoggedUser.size.getSizes({})
    setSizes(
      _sizes.data.map(({ id, name }) => {
        return { value: id, text: name }
      })
    )

    const _substrates = await call.forLoggedUser.substrate.getSubstrates({})
    setSubstrates(
      _substrates.data.map(({ id, name }) => {
        return { value: id, text: name }
      })
    )

    
    setLoading(false)
  }

  async function saveOrder () {
    let _order = {}
    _order.userId = use.getInputValueByName('userId')

    let order_items_array = []
    let _rows = document.querySelectorAll('#sub-items .sub-items-row')
    _rows.forEach(_r => {
      order_items_array.push(
        new Object({
          item_priceId: +_r.dataset.priceid,
          sizeId: +use.getInputValueByName('sizeId', _r),
          substrateId: +use.getInputValueByName('substrateId', _r),
          singleItemPrice: +use.getInputValueByName('singleItemPrice', _r),
          quantity: +use.getInputValueByName('quantity', _r)
        })
      )
    })

    _order.orderItems = order_items_array
    //return false;
    const _updateOrderResponse = await call.forLoggedUser.order.createOrder(
      _order
    )
    if (_updateOrderResponse.success) {
      setAlertBoxParameters({
        active: true,
        type: 'success',
        message: 'Successfully saved',
        timeout: 5000
      })
      setOrder(_updateOrderResponse.data)
    } else {
      setAlertBoxParameters({
        active: true,
        type: 'error',
        message: 'Something bad happened',
        timeout: 5000
      })
    }
  }

  const addPriceRow = () => {
    let _newRow = [...orderItems]
    _newRow.push({ id: _newRow.length, sizeId: 0, substrateId: 0, price: 0 })
    setOrderItems(_newRow)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    !loading &&
    statuses &&
    countries && (
      <React.Fragment>
        <form className='form'>
          <h4 className='form-title'>
            Add New Order
          </h4>
          <label className='group-label'>Order</label>
          <div className='row'>
            <Input
              type={'text'}
              label={'Number'}
              disabled={true}
              fieldName={'orderNumber'}
              col={{ default: 12, sm: 6, md: 4 }}
            />
            <Input
              type={'select'}
              label={'User Name'}
              fieldName={'userId'}
              //data={[{ value: order?.userId, text: order.user?.userName }]}
              col={{ default: 12, sm: 6, md: 2 }}
            />
            <Input
              type={'text'}
              label={'Sub Total'}
              fieldName={'subTotal'}
              disabled={true}
              col={{ default: 12, sm: 6, md: 2 }}
            />
            <Input
              type={'text'}
              label={'HST'}
              fieldName={'hst'}
              disabled={true}
              col={{ default: 12, sm: 6, md: 2 }}
            />
            <Input
              type={'text'}
              label={'Total Amount'}
              fieldName={'totalAmount'}
              disabled={true}
              col={{ default: 12, sm: 6, md: 2 }}
            />
            <Input
              type={'text'}
              label={'Order Date'}
              fieldName={'createDate'}
              disabled={true}
              col={{ default: 12, sm: 6, md: 2 }}
            />

            <Input
              type={'text'}
              label={'Last Modified'}
              fieldName={'modifiedDate'}
              disabled={true}
              col={{ default: 12, sm: 6, md: 2 }}
              value={'-'}
            />
          </div>
          <label className='group-label'>Order Items</label>
          <React.Fragment>
            <div id='sub-items'>
                    <OrderItemRow
                      key={1}
                      orderItem={{}}
                      sizeList={sizes}
                      substrateList={substrates}
                    />
            </div>
          </React.Fragment>
          <div className='action-buttons'>
            <button type='button' className='back-button'>
              Back
            </button>
            <button type='button' className='save-button' onClick={saveOrder}>
              Save
            </button>
            <button type='button' className='delete-button'>
              Delete
            </button>
          </div>
          <label className='group-label'>Client Info</label>
          {/* <div className='info-container'>
            <table>
              <tbody>
                <tr className='content-row'>
                  <td className='subject-label'>Email:</td>
                  <td>{order.user?.email}</td>
                </tr>
                <tr className='content-row'>
                  <td className='subject-label'>First Name:</td>
                  <td>{order.user?.firstName}</td>
                </tr>
                <tr className='content-row'>
                  <td className='subject-label'>Last Name:</td>
                  <td>{order.user?.lastName}</td>
                </tr>
                <tr className='content-row'>
                  <td className='subject-label'>Company:</td>
                  <td>{order.user?.companyName}</td>
                </tr>
                <tr className='content-row'>
                  <td className='subject-label'>Phone:</td>
                  <td>{order.user?.phone}</td>
                </tr>
                <tr className='content-row'>
                  <td className='subject-label'>Country:</td>
                  <td>
                    {countries.find(c => c.value == order.user?.countryId).text}
                  </td>
                </tr>
                <tr className='content-row'>
                  <td className='subject-label'>City:</td>
                  <td>{order.user?.city}</td>
                </tr>
                <tr className='content-row'>
                  <td className='subject-label'>Address:</td>
                  <td>{order.user?.address}</td>
                </tr>
                <tr className='content-row'>
                  <td className='subject-label'>PostCode:</td>
                  <td>{order.user?.postalCode}</td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </form>
      </React.Fragment>
    )
  )
}



function OrderItemRow ({ orderItem, sizeList, substrateList }) {
  const [_orderItem, _setOrderItem] = useState([])
  const [_priceList, _setPriceList] = useState([])
  const [loading, setLoading] = useState(false)
  var priceList = []

  const getData = async () => {
    const _priceListResponse =
      await call.forLoggedUser.item.getPriceListByItemId({
        itemId: orderItem.itemId
      })
    if (_priceListResponse.success) {
      console.log('success')
      _setPriceList(_priceListResponse.data)
    }
  }

  const removePriceRow = e => {}
  useEffect(() => {
    _setOrderItem(orderItem)
  }, [orderItem])

  useEffect(() => {
  }, [])

  const getPrice = async() => {
    console.log(_priceList)
    let priceItem = _priceList.find(
      p => p.sizeId == _orderItem.sizeId && p.substrateId == _orderItem.substrateId
    )
    console.log(priceItem)
    if (priceItem) {
      let order_item = _orderItem;
      order_item.price = priceItem.price;
      order_item.totalAmount = priceItem.price * _orderItem.quantity;
      _setOrderItem(order_item)
    }
  }

  return (
    <>
      {!loading && _orderItem && sizeList && substrateList && _priceList && (
        <div
          key={_orderItem.id}
          data-index={_orderItem.id}
          data-priceid={_orderItem.item_PriceId}
          className='row sub-items-row'
        >
          <span className='item-number'>{_orderItem.rowOrder}</span>
          <Input
            type={'text'}
            label={'Number'}
            fieldName={'itemNumber'}
            col={{ default: 12, sm: 4, md: 3 }}
            value={_orderItem.itemNumber}
            
          />

          {sizeList && (
            <Input
              type={'select'}
              label={'Size'}
              fieldName={'sizeId'}
              col={{ default: 12, sm: 4, md: 2 }}
              data={sizeList.filter(s =>
                [...new Set([..._priceList].map(p => p.sizeId))].includes(
                  s.value
                )
              )}
              value={_orderItem.sizeId}
              eventHandler={[
                {
                  type: 'change',
                  handler: e => {
                    let order_item = _orderItem;
                    order_item.sizeId = +e.target.value;
                    _setOrderItem(order_item)
                    getPrice(_priceList)
                  }
                  //(e)=>getPrice(e.target.value,item.substrateId)
                }
              ]}
            />
          )}

          {substrateList && (
            <Input
              type={'select'}
              label={'Substrate'}
              fieldName={'substrateId'}
              col={{ default: 12, sm: 4, md: 2 }}
              data={substrateList.filter(s =>
                [...new Set([..._priceList].map(p => p.substrateId))].includes(
                  s.value
                )
              )}
              value={_orderItem.substrateId}
              eventHandler={[
                {
                  type: 'change',
                  handler: e => {
                    let order_item = _orderItem;
                    order_item.substrateId = +e.target.value;
                    _setOrderItem(order_item)
                    getPrice(_priceList)
                  }
                }
              ]}
            />
          )}

          <Input
            type={'number'}
            label={'Quantity'}
            fieldName={'quantity'}
            col={{ default: 6, sm: 2, md: 1 }}
            value={_orderItem.quantity}
          />

          <Input
            type={'number'}
            label={'Price'}
            fieldName={'singleItemPrice'}
            col={{ default: 6, sm: 2, md: 1 }}
            value={_orderItem.singleItemPrice}
          />

          <Input
            type={'number'}
            label={'Line total'}
            fieldName={'totalPrice'}
            disabled={true}
            col={{ default: 6, sm: 2, md: 2 }}
            value={_orderItem.totalPrice}
          />
          <div className='col-12 col-sm-2 col-md-1'>
            <div className='input-box'>
              <label></label>
              <div>
                <img
                  className='row-delete'
                  data-id={_orderItem.id}
                  src='/trash.svg'
                  onClick={removePriceRow}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default _OrderAdd
