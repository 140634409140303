import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Input from '../_Components/Input'
import Variables from '../../global'

function _SubstrateEdit () {
  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [substrate, setSubstrate] = useState()

  const token = localStorage.getItem('tpp_token')

  useEffect(() => {
    const getSubstrate = async () => {
      setLoading(true)
      axios
        .get(`${Variables.base_url}/api/Substrate/GetSubstrates?id=${id}`, {
          headers: {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
          }
        })
        .then(res => {
          if (res.data.success) {
            setSubstrate(res.data.data[0])
          } else {
          }
        })
        .catch(resp => {
          console.error(
            resp.response.data.error.code,
            resp.response.data.error.message
          )
          navigate('/admin/substrates', { replace: true })
        })
        .finally(() => {
          setLoading(false)
        })
    }

    getSubstrate()
  }, [])

  const saveSubstrate = async statusId => {
    setLoading(true)

    let substrate = {}
    substrate.id = id
    substrate.name = getInputValueByName('name')
    substrate.description = getInputValueByName('description')
    substrate.enabled = getInputValueByName('enabled')
    substrate.statusId = statusId
    setSubstrate(substrate)
    //return;

    const response = await axios.put(
      `${Variables.base_url}/api/substrate/updatesubstrate`,
      substrate,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    if (response.data.success) {
      navigate('/admin/substrates', { replace: true })

      setLoading(false)
    }
  }

  const getInputValueByName = name => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    if (element.length === 1) {
      return element[0].value || element[0].text
    } else if (element.length > 1) {
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  }

  return (
    !loading && (
      <React.Fragment>
        <form className='form'>
          <h4 className='form-title'>{substrate.name}</h4>
          <div className='row'>
            <Input
              type={'text'}
              label={'Name'}
              fieldName={'name'}
              col={{ default: 12, md: 4 }}
              value={substrate.name}
            />

            <Input
              type={'text'}
              label={'Description'}
              fieldName={'description'}
              col={{ default: 12, sm: 12, md: 5 }}
              value={substrate.description}
            />

            <Input
              type={'checkbox'}
              label={'Enabled'}
              fieldName={'enabled'}
              col={{ default: 12, sm: 12, md: 12 }}
              value={substrate.enabled}
              eventHandler={{ 
                type:'change',
                handler: function(e){
                  e.target.checked? setSubstrate({...substrate, enabled:1}):setSubstrate({...substrate, enabled:0});
                  e.target.removeEventListener('change',this.handler)
                }}}
            />
          </div>

          <div className='action-buttons'>
            <button
              type='button'
              className='back-button'
              onClick={function () {
                navigate('/admin/substrates', { replace: true })
              }}
            >
              Back
            </button>
            <button
              type='button'
              className='save-button'
              onClick={function () {
                saveSubstrate(1)
              }}
            >
              Save
            </button>

            <button
              type='button'
              className='delete-button'
              
              onClick={function () {
                alert("Substrate deletions can be risky because of set product prices and orders in process. Extra analytics should be applied")
                //saveSubstrate(-1)
                //navigate('/admin/substrates', { replace: true })
              }}
            >
              Delete
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  )
}

export default _SubstrateEdit
