import { configureStore } from '@reduxjs/toolkit'
import cartReducer from './cartSlice'
import dashboardReducer from './dashboardSlice'
import userReducer from './userSlice'
import viewSettingsReducer from './viewSettingsSlice'

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    user: userReducer,
    dashboard: dashboardReducer,
    viewSettings: viewSettingsReducer
  },
})