import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { call } from '../Methods'
import { use } from '../Helpers'
import { useDispatch,useSelector } from 'react-redux'
import { removeUser } from '../Store/userSlice'

function Logout () {
  let navigate = useNavigate()
  const dispatch = useDispatch();
  
  const Logout = async()=> {
    const _logoutResponse = await call.user.logout();
    
    console.log('Logout',_logoutResponse);
    navigate('/',{replace:true})
    dispatch(removeUser())
    use.resetUserLoginInfo();
  }

  useEffect(()=>{
    Logout();
  },[])
 
  return <></>
}

export default Logout
