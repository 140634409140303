import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Input from '../_Components/Input'
import Variables from '../../global'

function _SubstrateAdd () {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const formRef = useRef()
  let token = localStorage.getItem('tpp_token')

  const [substrate, setSubstrate] = useState({
    id: undefined,
    name: undefined,
    description: undefined,
    enabled: undefined
  })

  const saveSubstrate = async statusId => {
    setLoading(true)

    let _substrate = {}
    _substrate.name = getInputValueByName('name')
    _substrate.description = getInputValueByName('description')
    _substrate.enabled = substrate.enabled
    setSubstrate(_substrate)
    //Substrate;
    const response = await axios.post(
      `${Variables.base_url}/api/substrate/createsubstrate`,
      _substrate,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    if (response.data.success) {
      navigate('/admin/substrates', { replace: true })
      formRef.current.reset()
      setLoading(false)
    }
  }

  const getDatasetValueByName = (name, dataKey) => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    return element.map(el => el.dataset[dataKey])
  }

  const getInputValueByName = name => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    if (element.length === 1) {
      return element[0].value || element[0].text
    } else if (element.length > 1) {
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  }

  useEffect(() => {}, [])

  return (
    <React.Fragment>
      <form className='form' ref={formRef}>
        <h4 className='form-title'>{'Add New Substrate'}</h4>
        {loading && <div className='loader'>Loading...</div>}
        <div className='row'>
          <Input type={'hidden'} fieldName={'id'} />
          <Input
            type={'text'}
            label={'Name'}
            fieldName={'name'}
            col={{ default: 12, md: 4 }}
            validate={function (value) {
              if (value == '0') {
                return false
              }
            }}
          />

          <Input
            type={'text'}
            label={'Description'}
            fieldName={'description'}
            col={{ default: 12, md: 5 }}
            value={null}
          />

          <Input
            type={'checkbox'}
            label={'Enabled'}
            fieldName={'enabled'}
            col={{ default: 12, md: 12 }}
            eventHandler={{
              type:"change",
              handler:function(e){
                e.target.checked? setSubstrate({...substrate, enabled:1}):setSubstrate({...substrate, enabled:0});
                e.target.removeEventListener('change',this.handler)
              }
            }} 
          />
        </div>

        <div className='action-buttons'>
          <button
            type='button'
            className='back-button'
            onClick={function () {
              navigate('/admin/substrates', { replace: true })
            }}
          >
            Back
          </button>
          <button
            type='button'
            className='save-button'
            onClick={function () {
              saveSubstrate(1)
            }}
          >
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default _SubstrateAdd
