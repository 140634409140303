import React, { useState, useEffect, useRef } from 'react'
import { call } from '../Methods'
import Input from '../Admin/_Components/Input'
import ValidateInput from '../ValidationDefaults'

function ContactForm ({ requestTypeList = [] }) {
  const [contacts, setContacts] = useState('Loading...')
  const [requestTypes, setRequestTypes] = useState([])
  const [loading, setLoading] = useState(false)
  const formRef = useRef()

  const ValidateAs = {
    fullname: ['required', 'fullName'],
    email: ['required', 'email'],
    phone: ['required'],
    requestType: ['required'],
    message: ['required']
  }

  const submitRequest = async e => {
    setLoading(true)
    e.preventDefault()
    let validationResults = []
    ;[...e.target.elements].forEach(input => {
      if (ValidateAs[input.name]) {
        let errorSpan = document.querySelector(
          `span.warning-message[for=${input.name}]`
        )

        let result = ValidateInput(
          input.name,
          ValidateAs[input.name],
          input.value
        )
        validationResults.push(result)

        if (!result.result) {
          errorSpan.classList.add('show')
          errorSpan.innerText = result?.message
        }
      }
    })

    let validationResult = validationResults.every(r => r.result === true)

    if (validationResult) {
      let _request = {}
      ;[...e.target.elements].forEach(input => {
        _request[input.name] =
          input.type != 'file' ? input.value : input.dataset['value']
        if (input.type == 'file') {
        }
      })

      //return;
      const _clientRequest = await call.clientRequest.newRequest(_request)
      if (_clientRequest.success) {
        alert('Your request has been sent')
        formRef.current.reset()
      } else {
        alert('error')
      }
    }
    setLoading(false)
  }

  const getData = async () => {
    setLoading(true)
    const _requestTypes = await call.clientRequest.getClientRequestTypes()
    setRequestTypes(
      _requestTypes.map(({ id, name }) => {
        return { value: id, text: name }
      })
    )
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <div className='form contact-form'>
        <div className='form-header header-left'>
          <h1 className='main'>Contact Us</h1>
          <p className='sub'>
            {' '}
            Have a question for us? We do our best to respond to everything
            within 24 hours.
          </p>
        </div>
        <form
          id='client-request-form'
          ref={formRef}
          onSubmit={e => {
            submitRequest(e)
          }}
        >
          <div className='input-box'>
            <Input
              type='select'
              fieldName={'requestType'}
              data={requestTypes}
              disabled={loading}
            />
          </div>
          <div className='input-box'>
            <Input
              type='text'
              fieldName={'fullName'}
              label={'Full Name'}
              required={true}
              disabled={loading}
            />
          </div>
          <div className='input-box'>
            <Input
              type='text'
              fieldName={'email'}
              label={'E-mail'}
              required={true}
              disabled={loading}
            />
          </div>
          <div className='input-box'>
            <Input
              type='text'
              fieldName={'phone'}
              label={'Phone'}
              required={true}
              disabled={loading}
            />
          </div>
          <div className='input-box'>
            <Input
              type='textarea'
              fieldName={'message'}
              label={'Message'}
              required={true}
              disabled={loading}
            />
          </div>
          <div className='input-box'>
            <Input
              type={'file'}
              id={'test'}
              fieldName={'attachmentBase64'}
              multiple={false}
              acceptFileFormats={'image/*,.pdf, .psd'}
              required={false}
              data-value={null}
              data={null}
              disabled={loading}
            />
          </div>
          <div className='button-box'>
            <button
              type='submit'
              className='button button-full-width'
              disabled={loading}
            >
              {loading ? 'Please, wait...' : 'Send Request'}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ContactForm
