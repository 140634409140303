import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { call } from '../Methods'
import Input from '../Admin/_Components/Input'
import ValidateInput from '../ValidationDefaults'
import { useDispatch, useSelector } from 'react-redux'
import { use } from '../Helpers'

export default function LoginForm ({ redirectUrl }) {
  let navigate = useNavigate()

  const ValidateAs = {
    email: ['required', 'email'],
    password: ['required']
  }

  async function Login (e) {
    e.preventDefault()
    let email = document.querySelector('.login-form input[name="email"]').value
    let password = document.querySelector(
      '.login-form input[name="password"]'
    ).value

    let validationResults = []
    ;[...e.target.elements].forEach(input => {
      if (ValidateAs[input.name]) {
        let errorSpan = document.querySelector(
          `span.warning-message[for=${input.name}]`
        )

        let result = ValidateInput(
          input.name,
          ValidateAs[input.name],
          input.value
        )
        validationResults.push(result)

        if (!result.result) {
          errorSpan.classList.add('show')
          errorSpan.innerText = result?.message
        }
      }
    })

    let validationResult = validationResults.every(r => r.result === true)

    if (validationResult) {
      const _result = await call.user.login(email, password)
      console.log(_result)
      if (_result?.success) {
        localStorage.setItem('tpp_token', _result.data)
        if (!localStorage.getItem('tpp_userSettings')) {
          localStorage.setItem(
            'tpp_userSettings',
            JSON.stringify({
              searchSettings: { sort: 'desc', pageSize: '10', pageNumber: 1 }
            })
          )
        }
        navigate('/redirect', { state: { url: redirectUrl }, replace: true })
      } else {
        alert(_result.error.message)
      }
    }
  }

  return (
    <div className='form form-container login-form'>
      <div className='form-header'>
        <h1 className='main'>Sign in</h1>
        <span className='sub' id='create-new'>
          or create a <Link to='/register'>new account</Link>
        </span>
      </div>
      <form className='form' noValidate method='post' onSubmit={Login}>
        <Input
          label={'E-mail'}
          fieldName={'email'}
          type={'email'}
          validateAs={'email'}
          eventHandler={[
            {
              type: 'keyup',
              handler: e => (e.key === 'Enter' ? Login(e) : null)
            }
          ]}
        />
        <Input
          label={'Password'}
          fieldName={'password'}
          type={'password'}
          validateAs={'password'}
          eventHandler={[
            {
              type: 'keyup',
              handler: e => (e.key === 'Enter' ? Login(e) : null)
            }
          ]}
        />
        <div className='button-box'>
          <button className='button button-full-width' type='submit'>
            Login
          </button>
        </div>
        <span id='forgot-password'>
          <Link to='/forgot-password'>Forgot password</Link>
        </span>
      </form>
    </div>
  )
}
