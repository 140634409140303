import React, { useState, useEffect, useRef } from 'react'
import { call } from '../Methods'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import Input from '../Admin/_Components/Input'
import ValidateInput from '../ValidationDefaults'
import { useSelector, useDispatch } from 'react-redux'
import Variables from '../global'
import WhyChooseUs from '../Components/WhyChooseUs'
import ContactForm from './ContactForm'

function Contact () {
  const [contacts, setContacts] = useState(null)
  const [loading, setLoading] = useState(true)
  const contentGroupName = 'Contacts'

  window.scrollTo(0, 0)

  const getData = async () => {
    setLoading(true)
    const _contactDetails = await call.content.getContents({
      groupName: contentGroupName
    })
    setContacts(_contactDetails)
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  // if (loading) return <div className='loading'>Loading...</div>
  if (!contacts) return <div className='loading'>Loading...</div>
  return (
    <>
      <div className='page contact-page'>
        <div className='page-content'>
          <div className='page-section'>
            <div className='row'>
              <div className='col-12 col-md-6'>
                {!loading && <ContactForm/>}
              </div>
              <div className='col-12 col-md-6'>
                {contacts && (
                  <div className='contact-details'>
                    <div className='contact-info'>
                      <span className='icon'>
                        <img src='/Icons/telephone.png' />
                      </span>
                      <span className='info'>
                        <a
                          href={`tel:${
                            contacts.find(c => c.name == 'phone')?.['value']
                          }`}
                        >
                          {contacts.find(c => c.name == 'phone')?.['value']}
                        </a>
                      </span>
                    </div>
                    <div className='contact-info'>
                      <span className='icon'>
                        <img src='/Icons/mail.png' />
                      </span>
                      <span className='info'>
                        <a
                          href={`mailto:${
                            contacts.find(c => c.name == 'email')?.['value']
                          }`}
                        >
                          {contacts.find(c => c.name == 'email')?.['value']}
                        </a>
                      </span>
                    </div>
                    <div className='contact-info'>
                      <span className='icon'>
                        <img src='/Icons/placeholder.png' />
                      </span>
                      <span className='info'>
                        {contacts.find(c => c.name == 'address')?.['value']}
                      </span>
                    </div>
                    <div className='map'>
                      <iframe
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d537680.0821703869!2d-80.01026499067596!3d43.67453327081664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON%2C%20Canada!5e0!3m2!1sen!2sus!4v1704390124402!5m2!1sen!2sus'
                        width='600'
                        height='450'
                        style={{ border: 0 }}
                        allowFullScreen=''
                        loading='lazy'
                        referrerPolicy='no-referrer-when-downgrade'
                      ></iframe>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <WhyChooseUs />
      </div>
    </>
  )
}

export default Contact
