import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Input from '../_Components/Input'
import Variables from '../../global'
import {use} from '../../Helpers.js'

function _SizeAdd () {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const formRef = useRef()
  let token = localStorage.getItem('tpp_token')

  const [size, setSize] = useState({
    id: undefined,
    name: undefined,
    description: undefined,
    enabled: undefined
  })

  const saveSize = async statusId => {
    setLoading(true)

    let _size = {}
    _size.name = use.getInputValueByName('name')
    _size.description = use.getInputValueByName('description')
    _size.enabled = size.enabled
    setSize(_size)
    //return;
    const response = await axios.post(
      `${Variables.base_url}/api/size/createsize`,
      _size,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    if (response.data.success) {
      navigate('/admin/sizes', { replace: true })
      formRef.current.reset()
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <form className='form' ref={formRef}>
        <h4 className='form-title'>{'Add New Size'}</h4>
        {loading && <div className='loader'>Loading...</div>}
        <div className='row'>
          <Input type={'hidden'} fieldName={'id'} />
          <Input
            type={'text'}
            label={'Name'}
            fieldName={'name'}
            col={{ default: 12, md: 4 }}
            validate={function (value) {
              if (value == '0') {
                return false
              }
            }}
          />

          <Input
            type={'text'}
            label={'Description'}
            fieldName={'description'}
            col={{ default: 12, md: 5 }}
            value={null}
          />

          <Input
            type={'checkbox'}
            label={'Enabled'}
            fieldName={'enabled'}
            col={{ default: 12, md: 12 }}
            eventHandler={{
              type:"change",
              handler:function(e){

                e.target.checked? setSize({...size, enabled:1}):setSize({...size, enabled:0})
              }
            }} 
          />
        </div>

        <div className='action-buttons'>
          <button
            type='button'
            className='back-button'
            onClick={function () {
              navigate('/admin/sizes', { replace: true })
            }}
          >
            Back
          </button>
          <button
            type='button'
            className='save-button'
            onClick={function () {
              saveSize(1)
            }}
          >
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default _SizeAdd
