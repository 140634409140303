import React, { Fragment, useEffect, useState } from 'react'
import { use } from '../Helpers'
import { call } from '../Methods'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { saveUser, removeUser } from '../Store/userSlice'

function Redirect () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {state} = useLocation();

  const CheckUser = async () => {
    let token = use.token();
    if(!token){
      navigate('/login', { replace: true });
      return;
    }
    const _checkTokenResponse = await call.user.checkToken(token)
    
    if (_checkTokenResponse?.success) {
      const userInfo = _checkTokenResponse.data
      console.log(userInfo)
      dispatch(saveUser(userInfo))
      sessionStorage.setItem('tpp_userInfo',JSON.stringify(userInfo));

      switch (userInfo.roleId) {
        case 1: {
          //client
          navigate(state.url?state.url:`/dashboard/${userInfo.userName}`, { replace: true })
          break;
        }
        case 2: {
          //admin
          navigate('/admin/dashboard', { replace: true })
          break;
        }
        default: {
          //no role
          navigate('/home', { replace: true })
        }
      }
    } else {
      dispatch(removeUser())
      use.resetUserLoginInfo();
      navigate('/login', { replace: true })
    }
  }

  useEffect(() => {
    CheckUser()
  }, [])

  return (
    <>
      <p>Redirecting...</p>
    </>
  )
}

export default Redirect
