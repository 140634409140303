import {
  Routes,
  Route,
  Link,
  useNavigate,
  BrowserRouter,
  Outlet
} from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import './ClientApp.scss'
import Header from '.././Components/Header'
import Footer from '.././Components/Footer'
import { use } from '../Helpers'


function ClientLayout ({ state }) {
  const [userInfo,setUserInfo] = useState()
  return (
    <React.Fragment>
      <Header />
      <Outlet />
      <Footer />
    </React.Fragment>
  )
}

export default ClientLayout
