import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.css'
import { Provider } from 'react-redux'
import { store } from './Store/_store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

reportWebVitals()
