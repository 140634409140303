import React, { useEffect, useRef } from "react"

function MessageBox({parameters}){
    const _ref = useRef();

    useEffect(()=>{
        if(parameters.active){
            setTimeout(()=>{
                _ref.current.classList.add('show');
                setTimeout(()=>{
                    _ref.current.classList.remove('show');
                },parameters.timeout)
            },700)
        }  
    },[parameters])

    return (
        <React.Fragment>
            <div ref={_ref} className={`alert-box ${parameters.type}`}>
                {parameters.message}
            </div>
        </React.Fragment>
    )
}

export default MessageBox