import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Table from '../_Components/AdminTable'
import { useTable } from 'react-table'
import Variables from '../../global'
import { call } from '../../Methods'

function _Substrates () {
  const [tableData, setTableData] = useState()

  const defaultParameters = {
    statusId: '',
    pageNumber: 1,
    pageSize: 10,
    sort: 'desc'
  }
  const userDefinedParameters = JSON.parse(
    localStorage.getItem('tpp_userSettings')
  ).searchSettings
  let _parameters = {}
  Object.assign(
    _parameters,
    defaultParameters,
    userDefinedParameters.searchSettings
  )
  const [parameters, setParameters] = useState(_parameters)

  let tableColumns = [
    {
      title: 'Id',
      property: 'id'
    },
    {
      title: 'Name',
      property: 'name'
    },
    {
      title: 'Description',
      property: 'description'
    },
    {
      title: (
        <React.Fragment>
          Enabled
          <span
            className='column-icon'
            title='Changes on this field will be considered only in new records'
          >
            &#9432;
          </span>
        </React.Fragment>
      ),
      property: 'enabled',
      type: 'checkbox'
    },
    {
      title: 'Actions',
      property: 'id',
      type: 'actions-list',
      mutate: value => {
        return (
          <>
            <Link to={`/admin/substrate/${value}`}>
              <img
                className='action-icon'
                title={'Edit'}
                src={'/Icons/edit.png'}
              />
            </Link>
          </>
        )
      }
    }
  ]

  const getData = async (parameters) => {
    const _substrates = await call.forLoggedUser.substrate.getSubstrates({})
    if(_substrates.success){
      setTableData(_substrates)
    }else{
      alert(_substrates.error.message);
    }
    return _substrates
  }

  useEffect(() => {
    getData(parameters)
  }, [parameters])

  return (
    <React.Fragment>
      <h3 className='page-title'>Substrates</h3>
      {tableData && <Table
        parameters={parameters}
        columns={tableColumns}
        loading={tableData?false:true}
        data={tableData.data}
        addNew={{ enabled: true, url: '/admin/substrate/add' }}
        pagination={{ enable: false }}
      />}
    </React.Fragment>
  )
}

export default _Substrates
