import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ContactForm from './ContactForm.js'
import { useDispatch, useSelector } from 'react-redux'
import Variables from '../global.js'
import { updateItem, addItemToCart } from '../Store/cartSlice'
import { call } from '../Methods.js'
import Vector from '../Vector.js'

function Item () {
  const dispatch = useDispatch()
  const { id } = useParams()
  const cart = useSelector(state => state.cart.items)
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState([{}])
  const [images, setImages] = useState([])
  const [sizes, setSizes] = useState([])
  const [substrates, setSubstrates] = useState([])
  const [priceList, setPriceList] = useState([])
  const [price, setPrice] = useState(0)
  const [formState, setFormState] = useState(false)
  const [count, setCount] = useState(1)

  async function loadItemData () {
    setLoading(true)
    let _item
    let _substrates
    let _sizes
    window.scrollTo(0, 0)
    const _itemResponse = await call.item.getItem({ id: id })
    const _substratesResponse = await call.substrate.getSubstrates({})
    const _sizesResponse = await call.size.getSizes({})

    {
      _item = _itemResponse

      setItem(_item)

      _item.prices.map((p, index) => {
        p.selected = index === 0 ? 1 : 0
      })

      setPriceList(_item.prices)
      setPrice(_item.prices[0])

      setImages(
        _item.images.map(
          image =>
            (image.imageUrl = `${Variables.base_url}/api/images/thumbnail-lg/${image.imageUrl}`)
        )
      )
    }

    {
      _substrates = _substratesResponse
        .map(({ id, name, order }) => {
          return { id, name, order }
        })
        .filter(s => {
          return [...new Set(_item.prices)]
            .map(ip => ip.substrateId)
            .includes(s.id)
        })
        .sort((a, b) => a.order > b.order)

      setSubstrates(_substrates)
    }

    {
      _sizes = _sizesResponse
        .map(({ id, name, order }) => {
          return { id, name, order }
        })
        .filter(s => {
          return [...new Set(_item.prices)].map(ip => ip.sizeId).includes(s.id)
        })
        .sort((a, b) => {
          console.log(a, b.order, a.order)
          return a.order - b.order
        })

      setSizes(_sizes)
    }

    setLoading(false)
  }

  const getSelectedPrice = () => {
    return priceList.find(p => p.selected === 1)
  }

  useEffect(() => {
    loadItemData()
  }, [])

  const changeItemCount = distinction => {
    setCount(count => (count + distinction >= 1 ? count + distinction : 1))
  }

  const addToCart = () => {
    let itemFound = false
    cart.forEach(element => {
      if (!itemFound && element.priceId == price.id) {
        dispatch(
          updateItem({
            itemId: element.itemId,
            priceId: price.id,
            sizeId: price.sizeId,
            substrateId: price.substrateId,
            price: price.price,
            active: 1,
            count: count
          })
        )
        itemFound = true
      }
    })

    if (itemFound === false) {
      dispatch(
        addItemToCart({
          itemId: item.id,
          priceId: price.id,
          sizeId: price.sizeId,
          substrateId: price.substrateId,
          price: price.price,
          active: 1,
          count: count
        })
      )
    }
  }
  const toggleForm = () => {
    setFormState(!formState)
  }

  const selectImage = e => {
    document
      .querySelectorAll('.image-list .image-list-item')
      .forEach(li => li.classList.remove('selected'))
    e.target.classList.add('selected')
    document.querySelector('.item-image img').src = e.target.src
  }

  if (loading) return <div className='loading'>Loading...</div>

  return (
    <React.Fragment>
      <main className='page item-page'>
        <div className='page-content'>
          <div className='item-content'>
            {loading ? (
              <div>Loading...</div>
            ) : (
              <React.Fragment>
                <p className='breadcrumb'>
                  <Link to={'/items'}>Products</Link> {' / '}{' '}
                  <Link to={`/items?categoryId=${item.categoryId}`}>
                    {item.categoryName}
                  </Link>{' '}
                  {' / '} {`${item.itemName}`}
                </p>
                <div className='row item-page-card'>
                  <div className='col-12 col-md-4 item-image'>
                    <img src={images[0]} />
                    <div className='image-list'>
                      {images.map((image, index) => {
                        return (
                          <img
                            key={index}
                            src={image}
                            className={`image-list-item ${
                              index === 0 ? 'selected' : ''
                            }`}
                            onClick={selectImage}
                          />
                        )
                      })}
                    </div>
                  </div>
                  <div
                    className='col-12 col-md-7'
                    style={{ padding: '0px 0px 0 35px' }}
                  >
                    <div className='item-header'>
                      <h1 className='name'>{item && item.itemName}</h1>
                    </div>
                    <div className='item-body'>
                      <div className='item-price'>
                        {!loading && price && `$ ${price.price}`}
                      </div>
                      <div className='item-details'>
                        <div className='item-properties'>
                          {!loading && sizes && sizes.length > 0 && (
                            <div className='item-sizes item-property'>
                              <label htmlFor='size'>
                                Size
                                {/* <span>({sizes.length} available)</span> */}
                              </label>
                              <div className='value-container'>
                                {[...sizes].map((size, index) => {
                                  return (
                                    <span
                                      className={`parameter-value${
                                        price && price?.sizeId == size.id
                                          ? ' selected'
                                          : ''
                                      }`}
                                      key={size.id}
                                      data-value={size.id}
                                      onClick={() => {
                                        let { substrateId } = price

                                        const _priceList = priceList
                                        _priceList.map(p => {
                                          p.selected =
                                            p.sizeId === size.id &&
                                            p.substrateId === substrateId
                                              ? 1
                                              : 0
                                        })
                                        setPriceList(_priceList)
                                        if (getSelectedPrice())
                                          setPrice(getSelectedPrice())
                                      }}
                                    >
                                      {size.name}
                                    </span>
                                  )
                                })}
                              </div>
                            </div>
                          )}

                          {!loading && substrates && substrates.length > 0 && (
                            <div className='item-substrates item-property'>
                              <label htmlFor='substrate'>
                                Substrate{' '}
                                {/* <span>({substrates.length} available)</span> */}
                              </label>
                              <div className='value-container'>
                                {[...substrates].map((substrate, index) => {
                                  return (
                                    <span
                                      className={`parameter-value${
                                        price?.substrateId == substrate.id
                                          ? ' selected'
                                          : ''
                                      }`}
                                      key={substrate.id}
                                      data-value={substrate.id}
                                      onClick={() => {
                                        let { sizeId } = price

                                        const _priceList = priceList
                                        _priceList.map(p => {
                                          p.selected =
                                            p.sizeId === sizeId &&
                                            p.substrateId === substrate.id
                                              ? 1
                                              : 0
                                        })
                                        setPriceList(_priceList)
                                        if (getSelectedPrice()) {
                                          setPrice(getSelectedPrice())
                                        }
                                      }}
                                    >
                                      {substrate.name}
                                    </span>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='item-description'>
                          <div dangerouslySetInnerHTML={{__html:item.description}}></div>
                        </div>
                        <div className='checkout'>
                          <div className='quantity'>
                            <span
                              className='buttons'
                              onClick={() => changeItemCount(-1)}
                            >
                              -
                            </span>
                            <span className='count'>{count}</span>
                            <span
                              className='buttons'
                              onClick={() => changeItemCount(1)}
                            >
                              +
                            </span>
                          </div>
                          <div className='add-to-cart'>
                            <div
                              onClick={addToCart}
                              className='add-item-to-cart'
                            >
                              + Add To Cart
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className='quote-request-banner row'>
          <span className='col-12 col-md-6'>
            Didn't find product with special size or substrate?
          </span>
          <button
            className='col-12 col-md-6 request-button'
            onClick={toggleForm}
          >
            Request for quotation
            <span>
              <span
                className='arrow'
                style={
                  formState
                    ? { transform: 'rotate(135deg)', top: '3px' }
                    : { transform: 'rotate(315deg)' }
                }
              ></span>
            </span>
          </button>
        </div>
        {formState && <ContactForm requestTypeList={[1, 2, 3, 5]} />}
        </div>

       
      </main>
    </React.Fragment>
  )
}

export default Item
