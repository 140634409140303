import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Table from '../_Components/AdminTable.js'
import { useTable } from 'react-table'
import Variables from '../../global'
import { call } from './../../Methods.js'

function _Partners () {
  const [tableData, setTableData] = useState()

  const defaultParameters = {
    enabled: ''
  }

  const userDefinedParameters = JSON.parse(
    localStorage.getItem('tpp_userSettings')
  ).searchSettings
  let _parameters = {}
  Object.assign(
    _parameters,
    defaultParameters,
    userDefinedParameters.searchSettings
  )
  
  const [parameters, setParameters] = useState(_parameters)

  let tableColumns = [
    {
      title: 'Name',
      property: 'name'
    },
    {
      title: 'Image',
      property: 'image.imageUrl',
      type: 'image',
      mutate: value => {
        return value
          ? `${Variables.base_url}/api/images/thumbnail-sm/${value}`
          : `/No_image_available.svg`
      }
    },
    {
      title: 'Status',
      property: 'statusName',
      type: 'label',
      mutate: value => {
        return value == 0 ? (
          <span className='label-green'>Draft</span>
        ) : (
          <span className='label-blue'>Published</span>
        )
      }
    },
    {
      title: 'Actions',
      property: 'id',
      type: 'actions-list',
      mutate: value => {
        return (
          <>
            <Link to={`/admin/partner/${value}`}>
              <img
                className='action-icon'
                title={'Edit'}
                src={'/Icons/edit.png'}
              />
            </Link>
          </>
        )
      }
    }
  ]

  const getData = async parameters => {
    const _partners = await call.forLoggedUser.partner.getPartners(parameters);
    setTableData(_partners)
    return _partners
  }

  useEffect(() => {
    getData(parameters)
  }, [parameters])


  return (
    <React.Fragment>
      <h3 className='page-title'>Partners</h3>
      {tableData && tableData.data && <Table
        parameters={parameters}
        columns={tableColumns}
        loading={tableData?false:true}
        data={tableData.data}
        addNew={{ enabled: true, url: '/admin/partner/add' }}
        pagination={{ enable: false }}
      />}
    </React.Fragment>
  )
}

export default _Partners
