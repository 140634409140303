import React from 'react';
import { Link } from "react-router-dom";

function PagesList() {
    return (
        <React.Fragment>
            <h3 className="page-title">Pages List</h3>
                <main className='pagelist'>
                    <section className="admin-pages">
                        <p>Admin</p>
                        <ul>
                            <li><Link to="/admin"><div className="page-name">Admin</div><div className="page-definition">Main Admin Page</div></Link></li>
                            <li><Link to="/admin/dashboard"><div className="page-name">Dashboard</div><div class="page-definition">Graphs about Sales, Orders and Items</div></Link></li>
                            <li><Link to="/admin/items"><div className="page-name">Items</div><div class="page-definition">List, create, edit, remove items</div></Link></li>
                            <li><Link to="/admin/orders"><div className="page-name">Orders</div><div class="page-definition">List, create, edit, remove orders</div></Link></li>
                            <li><Link to="/admin/users"><div className="page-name">Users</div><div class="page-definition">List, create, edit, remove users</div></Link></li>
                            <li><Link to="/login"><div className="page-name">Login</div><div class="page-definition">Log in to admin dashboard</div></Link></li>
                            <li><Link to="/logout"><div className="page-name">Logout</div><div class="page-definition">End session</div></Link></li>
                        </ul>
                    </section>
                    <section className="client-pages">
                        <p>Client</p>
                        <ul>
                            <li><Link to="/"><div className="page-name">Home</div><div class="page-definition">Home page / Requests redirecting to Items List page</div></Link></li>
                            <li><Link to="/items"><div className="page-name">Items</div><div class="page-definition">List and filter items</div></Link></li>
                            <li><Link to="/item/1"><div className="page-name">Item</div><div class="page-definition">Item details page</div></Link></li>
                            <li><Link to="/about"><div className="page-name">About</div><div class="page-definition">Info about Company</div></Link></li>                            
                            <li><Link to="/checkout"><div className="page-name">Checkout</div><div class="page-definition">Checkout and replace order</div></Link></li>
                        </ul>
                    </section>
                </main>
        </React.Fragment>
    );
}

export default PagesList;