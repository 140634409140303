import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Input from '../_Components/Input'
import Variables from '../../global'

function _SizeEdit () {
  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [size, setSize] = useState()

  const token = localStorage.getItem('tpp_token')

  useEffect(() => {
    const getSize = async () => {
      setLoading(true)
      axios
        .get(`${Variables.base_url}/api/Size/GetSizes?id=${id}`, {
          headers: {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
          }
        })
        .then(res => {
          if (res.data.success) {
            setSize(res.data.data[0])
          } else {
          }
        })
        .catch(resp => {
          console.error(
            resp.response.data.error.code,
            resp.response.data.error.message
          )
          navigate('/admin/sizes', { replace: true })
        })
        .finally(() => {
          setLoading(false)
        })
    }

    getSize()
  }, [])

  const saveSize = async statusId => {
    setLoading(true)

    let size = {}
    size.id = id
    size.name = getInputValueByName('name')
    size.description = getInputValueByName('description')
    size.enabled = getInputValueByName('enabled')
    size.statusId = statusId
    setSize(size)
    //return;

    const response = await axios.put(
      `${Variables.base_url}/api/size/updatesize`,
      size,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    if (response.data.success) {
      navigate('/admin/sizes', { replace: true })

      setLoading(false)
    }
  }

  const getInputValueByName = name => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    if (element.length === 1) {
      return element[0].value || element[0].text
    } else if (element.length > 1) {
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  }

  return (
    !loading && (
      <React.Fragment>
        <form className='form'>
          <h4 className='form-title'>{size.name}</h4>
          <div className='row'>
            <Input
              type={'text'}
              label={'Name'}
              fieldName={'name'}
              col={{ default: 12, md: 4 }}
              value={size.name}
            />

            <Input
              type={'text'}
              label={'Description'}
              fieldName={'description'}
              col={{ default: 12, sm: 12, md: 5 }}
              value={size.description}
            />

            <Input
              type={'checkbox'}
              label={'Enabled'}
              fieldName={'enabled'}
              col={{ default: 12, sm: 12, md: 12 }}
              value={size.enabled}
              eventHandler={{ 
                type:'change',
                handler: function(e){
                  e.target.checked? setSize({...size, enabled:1}):setSize({...size, enabled:0});
                }}}
            />
          </div>

          <div className='action-buttons'>
            <button
              type='button'
              className='back-button'
              onClick={function () {
                navigate('/admin/sizes', { replace: true })
              }}
            >
              Back
            </button>
            <button
              type='button'
              className='save-button'
              onClick={function () {
                saveSize(1)
              }}
            >
              Save
            </button>

            <button
              type='button'
              className='delete-button'
              
              onClick={function () {
                alert("Size deletions can be risky because of set product prices and orders in process. Extra analytics should be applied")
                //saveSize(-1)
                //navigate('/admin/sizes', { replace: true })
              }}
            >
              Delete
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  )
}

export default _SizeEdit
