

function Vector({icon,fill,style}){
    let star = null;
    switch(icon){
        case 'star': star = <svg style={style} width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.23928 1.34166C5.47928 0.604862 6.52168 0.604862 6.76088 1.34166L7.61688 3.97526C7.66917 4.13565 7.77084 4.27541 7.90733 4.37455C8.04383 4.47369 8.20818 4.52714 8.37688 4.52726H11.1465C11.9217 4.52726 12.2433 5.51926 11.6169 5.97526L9.37688 7.60246C9.2401 7.70169 9.13826 7.84167 9.08594 8.00234C9.03363 8.16302 9.03354 8.33613 9.08568 8.49686L9.94168 11.1305C10.1817 11.8673 9.33768 12.4809 8.70968 12.0249L6.46968 10.3977C6.33304 10.2985 6.16852 10.245 5.99968 10.245C5.83083 10.245 5.66631 10.2985 5.52968 10.3977L3.28968 12.0249C2.66248 12.4809 1.81928 11.8673 2.05848 11.1305L2.91448 8.49686C2.96662 8.33613 2.96652 8.16302 2.91421 8.00234C2.8619 7.84167 2.76005 7.70169 2.62328 7.60246L0.384077 5.97606C-0.242323 5.52006 0.080077 4.52806 0.854477 4.52806H3.62328C3.79211 4.52811 3.95664 4.47473 4.0933 4.37558C4.22995 4.27643 4.33174 4.13658 4.38408 3.97606L5.24008 1.34246L5.23928 1.34166Z" fill={fill}/>
        </svg>; break;
        default: return <>{icon}</>      
    }
    return <>{star}</>
}
export default Vector;