import React, { Fragment, useEffect, useRef, useState } from 'react'
import {  useLocation, useNavigate, Link } from 'react-router-dom'

import { use } from '../Helpers'
import { call } from '../Methods'
import Input from '../Admin/_Components/Input'
import ValidateInput from '../ValidationDefaults'
import LoginForm from '../Components/LoginForm'


export default function Login () {
  let navigate = useNavigate()
  const {state} = useLocation();

 useEffect(()=>{
  if(use.token()){
    navigate('/redirect',{replace:true});
  }
 },[])



  return (
      <div className="page account-page">
        <img className="svg-background" src="/Backgrounds/wave-haikei.svg"/>
        <div className="account-forms">
        <LoginForm redirectUrl={state?.url?state?.url:null}/>
        </div>
      </div>

    )
}
