import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Table from '../_Components/AdminTable'
import Variables from '../../global'
import { call } from '../../Methods'
import { Link } from 'react-router-dom'

function _Contents () {
  const [tableData, setTableData] = useState([])

  let tableColumns = [
    {
      title: 'Key',
      property: ['groupName', 'name'],
      mutate: values => {
        return values.join('.')
      },
      style: { header: { width: '25%' }, rows: { textAlign: 'left' } }
    },
    {
      title: 'Value',
      property: 'value',
      mutate: value => {
        return <span dangerouslySetInnerHTML={{ __html: value.length > 100
          ? value.substring(0, 100).concat('...')
          : value }}></span>
      },
      style: { header: { width: '40%' } }
    },
    {
      title: 'Last Modified',
      property: 'lastModified',
      mutate: value => {
        return value.toString().substring(0, 10)
      }
    },
    {
      title: 'Actions',
      property: 'id',
      type: 'actions-list',
      mutate: value => {
        return (
          <>
            <Link to={`/admin/content/${value}`}>
              <img
                className='action-icon'
                title={'Edit'}
                src={'/Icons/edit.png'}
              />
            </Link>
          </>
        )
      }
    }
  ]

  const getData = async () => {
    const _content = await call.forLoggedUser.content.getContents({});
    if(_content.success){
      setTableData(_content);
      console.log(_content)
    }
    
    return _content
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <React.Fragment>
      <h3 className='page-title'>Static Contents</h3>
      {tableData && tableData.data &&
        <Table
        columns={tableColumns}
        data={tableData.data}
        loading={tableData?false:true}
        pagination={{ enable: false }}
      />
      }
      
      
    </React.Fragment>
  )
}

export default _Contents
