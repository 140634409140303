import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Input from '../_Components/Input'
import { use } from '../../Helpers'
import { call } from '../../Methods'

export default function _UserAdd () {
  const {id} = useParams();
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [countries, setCountries] = useState(null)
  const [roles, setRoles] = useState(null)
  const [statuses, setStatuses] = useState(null)
  const [loading, setLoading] = useState(false)

  let colorsByValues = {
    '-3': '#ff5252',
    '-2': 'black',
    '-1': '#c4c4c4',
    0: '#FF9800',
    1: '#4242c4',
    2: '#c442c4',
    3: '#42c442',
    4: '#c4c442',
    5: '#42c490'
  }

  const getData = async () => {
    setLoading(true)
    const _roles = await call.forLoggedUser.user.getRoles({})
    if (_roles.success) {
      setRoles(_roles.data)
    }
    const _countries = await call.forLoggedUser.country.getCountryList({})
    if (_countries.success) {
      setCountries(_countries.data)
    }
    const _statuses = await call.forLoggedUser.statuses.getStatuses({
      type: 'User'
    })
    if (_statuses?.success) {
      setStatuses(_statuses.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  const saveData = async e => {
    setLoading(true)
    let uploaded_image = document.querySelector(
      'img[name="input-image-preview"]'
    )?.dataset['base64']

    let _user = {
      email:use.getInputValueByName('email'),
      userName:use.getInputValueByName('userName'),
      roleId: use.getInputValueByName('roleId'),
      password: use.getInputValueByName('password'),
      firstName: use.getInputValueByName('firstName'),
      lastName: use.getInputValueByName('lastName'),
      companyName: use.getInputValueByName('companyName'),
      phone: use.getInputValueByName('phone'),
      image: {
        base64: uploaded_image
      },
      countryId: use.getInputValueByName('countryId'),
      city: use.getInputValueByName('city'),
      address: use.getInputValueByName('address'),
      postalCode: use.getInputValueByName('postalCode'),
      active: use.getInputValueByName('active')
    }
    const _addUserResponse = await call.forLoggedUser.user.addUser(_user)

    if (_addUserResponse.success) {
      navigate(`/admin/user/${_addUserResponse.data.id}`,{replace:true})
    }
    setLoading(false);
  }

  return (
    !loading && statuses && countries && (
      <React.Fragment>
        <h4 className='page-title'>
          {id ? (
            <span>
              {user.email}{' '}
              <span
                style={{
                  float: 'right',
                  backgroundColor: colorsByValues[user.statusId]
                }}
                className='status-label'
              >
                {user.statusName}
              </span>
            </span>
          ) : (
            'Add New User'
          )}
        </h4>
        <form className='form'>
            <div className='row'>
              <Input
                type={'email'}
                label={'Email'}
                required
                fieldName={'email'}
                col={{ default: 12, sm: 6, md: 3 }}
              />
              <Input
                type={'text'}
                label={'User Name'}
                fieldName={'userName'}
                col={{ default: 12, sm: 6, md: 3 }}
              />
              <Input
                type={'select'}
                label={'Role'}
                required
                fieldName={'roleId'}
                data={roles.map(({ id, name }) => {
                  return { value: id, text: name }
                })}
                col={{ default: 12, sm: 6, md: 3 }}
              />
              <Input
                type={'text'}
                label={'Password'}
                required
                fieldName={'password'}
                col={{ default: 12, sm: 6, md: 3 }}
              />
              <Input
                type={'text'}
                label={'First Name'}
                required
                fieldName={'firstName'}
                col={{ default: 12, sm: 6, md: 3 }}
              />
              <Input
                type={'text'}
                label={'Last Name'}
                required
                fieldName={'lastName'}
                col={{ default: 12, sm: 6, md: 3 }}
              />
              <Input
                type={'text'}
                label={'Company Name'}
                fieldName={'companyName'}
                col={{ default: 12, sm: 6, md: 3 }}
              />
              <Input
                type={'text'}
                label={'Phone Number'}
                fieldName={'phone'}
                col={{ default: 12, sm: 6, md: 3 }}
              />
              <Input
                type={'checkbox'}
                label={'Enabled'}
                fieldName={'active'}
                col={{ default: 12, sm: 6, md: 3 }}
              />
              <label className='group-label'>Shipping Address</label>
              <Input
                type={'select'}
                label={'Country'}
                required
                fieldName={'countryId'}
                data={countries.map(({ id, name }) => {
                  return { value: id, text: name }
                })}
                col={{ default: 12, sm: 4, md: 2 }}
              />
              <Input
                type={'text'}
                label={'City'}
                fieldName={'city'}
                col={{ default: 12, sm: 4, md: 2 }}
              />
              <Input
                type={'text'}
                label={'Address'}
                fieldName={'address'}
                col={{ default: 12, sm: 12, md: 6 }}
              />
              <Input
                type={'text'}
                label={'Postal Code'}
                fieldName={'postalCode'}
                col={{ default: 12, sm: 4, md: 2 }}
              />
              <div className='action-buttons'>
                <button
                  type='button'
                  className='back-button'
                  onClick={() => navigate('/admin/users', { replace: true })}
                >
                  Back
                </button>
                <button
                  type='button'
                  className='save-button'
                  onClick={e => saveData()}
                >
                  Save
                </button>
              </div>
            </div>
        </form>
      </React.Fragment>
    )
  )
}
