import React, { useEffect, useState, useRef } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import './AdminTable.scss'

function Table ({
  columns,
  data = [],
  getMethod = null,
  pagination = { enable: false, settings: null },
  headerContent = null,
  addNew,
  loading = true
}) {
  const [dataLoading, setDataLoading] = useState(loading)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    setDataLoading(loading)
  }, [loading])


  const getData = async(params)=>{
    if(getMethod!=null){
      let _data = await getMethod(params)
      setTableData(_data.data)
    }
    
  }

  useEffect(() => {
    if (getMethod != null) {
      getData("salam")
    } else {
      setTableData(data)
    }
  }, [])



  const changeUserSettings = async (key, value) => {
    pagination.settings[key] = value
  }

  return (
    <div className='admin-table'>
      {loading && <div className='loading'>Loading...</div>}
      <div className='actions-header'>
        {addNew && addNew.enabled && (
          <Link to={addNew.url} className='add-new'>
            + Add New
          </Link>
        )}
      </div>
      {tableData && (
        <table className='admin-data'>
          <thead>
            <tr>
              {columns.map((column, index) => {
                return (
                  <th key={index + 1} style={column.style?.header}>
                    {column.title}
                  </th>
                )
              })}
            </tr>
            {headerContent && (
              <tr>
                <th className='table-sub-header' colSpan={columns.length}>
                  {headerContent}
                </th>
              </tr>
            )}
          </thead>
          <tbody>
            {tableData &&
              tableData.length > 0 &&
              tableData.map((d, index) => {
                return (
                  <tr key={index + 1}>
                    {columns.map((column, index) => {
                      let key = index + 1
                      let propertyIsArray = Array.isArray(column.property)
                      let value
                      if (propertyIsArray) {
                        value = []
                        column.property.forEach(p => {
                          if (p.includes('.')) {
                            let v
                            let _properties = p.split('.')
                            v = d
                            _properties.forEach(_property => (v = v[_property]))
                            value.push(v)
                          } else {
                            value.push(d[p])
                          }
                          if (!column.mutate) {
                            value = value.join(',')
                          }
                        })
                      } else {
                        if (column.property.includes('.')) {
                          let _properties = column.property.split('.')
                          value = d
                          _properties.forEach(
                            _property => (value = value[_property])
                          )
                        } else {
                          value = d[column.property]
                        }
                      }

                      if (column.mutate) {
                        value = column.mutate(value)
                      }

                      switch (column.type) {
                        case 'actions-list':
                          return (
                            <td className={column.type} key={key}>
                              {value}
                            </td>
                          )
                        case 'image':
                          return (
                            <td key={key}>
                              <img src={value} />
                            </td>
                          )
                        case 'label':
                          return (
                            <td className='label' key={key}>
                              {value}
                            </td>
                          )
                        case 'file':
                          return value ? (
                            <td className='file' key={key}>
                              {value}
                            </td>
                          ) : (
                            <td></td>
                          )
                        case 'checkbox':
                          return (
                            <td key={key}>
                              <input
                                type='checkbox'
                                name={column.property + '[]'}
                                readOnly
                                checked={value}
                                value={value}
                              />
                            </td>
                          )
                        default:
                          return (
                            <td style={column.style?.rows} key={key}>
                              {value}
                            </td>
                          )
                      }
                    })}
                  </tr>
                )
              })}
          </tbody>
        </table>
      )}
      {tableData && tableData.length === 0 && <div className='no-data'>No data</div>}
      <div className='table-footer'>
        {!dataLoading && pagination.enable && (
          <div className='pagination-wrapper'>
            <div className='page-size'>
              <label>Page size: </label>
              <select
                value={pagination.settings?.pageSize}
                onChange={e => {
                  changeUserSettings('pageSize', +e.target.value)
                }}
              >
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
            </div>
            <div className='page-size'>
              <label>Sort: </label>
              <select
                value={pagination.settings?.sort}
                onChange={e => {
                  //changeUserSettings('sort', e.target.value)
                }}
              >
                <option value='asc'>Ascending</option>
                <option value='desc'>Descending</option>
              </select>
            </div>
            <div className='pages'>
              {pagination.settings && (
                <React.Fragment>
                  <button className='info-button'>
                    <b>
                      {pagination.settings?.pageSize *
                        (+pagination.settings?.pageNumber - 1)}
                      -
                      {pagination.settings.pageSize *
                        +pagination.settings.pageNumber >
                      pagination.settings.length
                        ? pagination.settings.length
                        : pagination.settings.pageSize *
                          +pagination.settings.pageNumber}
                    </b>{' '}
                    of {pagination.settings.length || '...'}
                  </button>
                  <button
                    className='arrow-button'
                    onClick={e => {
                      // changeUserSettings(
                      //   'pageNumber',
                      //   +paginationSettings?.pageNumber - 1
                      // )
                    }}
                  >
                    {'<'}
                  </button>
                  <button
                    className='arrow-button'
                    onClick={e => {
                      // changeUserSettings(
                      //   'pageNumber',
                      //   +paginationSettings?.pageNumber + 1
                      // )
                    }}
                  >
                    {'>'}
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Table
