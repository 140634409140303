import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Input from '../_Components/Input'
import Variables from '../../global'
import { call } from '../../Methods'
import { upload } from '@testing-library/user-event/dist/upload'

function _CategoryEdit () {
  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [parentCategoriesList, setParentCategoriesList] = useState(null); 
  const [category, setCategory] = useState()

  let colorsByValues = {
    '-3': '#ff5252',
    '-2': 'black',
    '-1': '#c4c4c4',
    0: '#FF9800',
    1: '#4242c4',
    2: '#c442c4',
    3: '#42c442',
    4: '#c4c442',
    5: '#42c490'
  }

  const loadData = async() =>{
    setLoading(true)
    const _category = await call.forLoggedUser.category.getCategory({id:id});
   
    let _parentCategoriesList = await call.forLoggedUser.category.getCategories({type:1,enabled:1});

    if(_category.success){
      setCategory(_category.data)
    }else{
      navigate('/admin/categories',{replace:true})
    }

    if(_parentCategoriesList.success){
      _parentCategoriesList = [...[{value:'',text:'Select Category'}],..._parentCategoriesList.data.map(({id,name}) => {return { value: id, text: name }})];
      setParentCategoriesList(_parentCategoriesList);
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData();
  }, [])

  const saveCategory = async enabled => {
    setLoading(true);

    let image;

    let uploaded_image = document.querySelector(
      'img[name="input-image-preview"]'
    )

    if(uploaded_image){
      image = new Object({
        base64:   uploaded_image.dataset['base64'] || null,
        isNew:    uploaded_image.dataset['isnew']=== "true" ? true : false,
        imageUrl: uploaded_image.dataset['imageurl']
      })
    }    

    let _category = {}
    _category.id = +id
    _category.parentId = parseInt(getInputValueByName('parentId'))==NaN?0:parseInt(getInputValueByName('parentId'))
    _category.name = getInputValueByName('name')
    _category.description = getInputValueByName('description')
    _category.image = image
    _category.enabled = getInputValueByName('enabled')
    setCategory(_category)
    //return;


    const _newCategory = await call.forLoggedUser.category.updateCategory(_category)
    
    if (_newCategory.id) {
      setCategory(_newCategory)
      setLoading(false)
    }
  }

  const getInputValueByName = name => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    if (element.length === 1) {
      return element[0].value || element[0].text
    } else if (element.length > 1) {
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  }

  return (
    !loading && (
      <React.Fragment>
        <form className='form'>
          <h4 className='form-title'>{category.name}
          </h4>
          <div className='row'>
            <Input
              type={'text'}
              label={'Name'}
              fieldName={'name'}
              col={{ default: 12, md: 4 }}
              value={category.name}
            />

        {parentCategoriesList && <Input
            type={'select'}
            label={'Parent Category (optional)'}
            fieldName={'parentId'}
            col={{ default: 12, md: 4 }}
            data={parentCategoriesList}
            value={category?.parentId || ''}
          />}
            <Input
              type={'text'}
              label={'Description'}
              fieldName={'description'}
              col={{ default: 12, sm: 12, md: 4 }}
              value={category.description}
            />

            <Input
              type={'checkbox'}
              label={'Enabled'}
              fieldName={'enabled'}
              col={{ default: 12, sm: 12, md: 3 }}
              value={category.enabled}
              eventHandler={{ 
                type:'change',
                handler: function(e){
                  e.target.checked? setCategory({...category, enabled:1}):setCategory({...category, enabled:0});
                }}}
            />

              <Input
              type={'image'}
              label={'Image'}
              fieldName={'images'}
              col={{ default: 12, sm: 12, md: 12 }}
              value={category.image.imageurl}
              multiple={false}
              data={
                    category.image.imageUrl?[new Object({
                      src: `${Variables.base_url}/api/images/thumbnail-md/${category.image.imageUrl}`,
                      srclarge:`${Variables.base_url}/api/images/large/${category.image.imageUrl}`,
                      data: null,
                      isNew: false,
                      imageurl: category.image.imageUrl
                    })]:[]
                  }
              eventHandler={{
                type: 'change',
                handler: function (e) {
                  let target = e.target
                }
              }}
            />
          </div>

          <div className='action-buttons'>
            <button
              type='button'
              className='back-button'
              onClick={function () {
                navigate('/admin/categories', { replace: true })
              }}
            >
              Back
            </button>
            <button
              type='button'
              className='save-button'
              onClick={function () {
                saveCategory(1)
              }}
            >
              Save
            </button>

            <button
              type='button'
              className='delete-button'
              
              onClick={function () {
                alert("Category deletions can be risky because of set product prices and orders in process. Extra analytics should be applied")
                //saveSize(-1)
                //navigate('/admin/sizes', { replace: true })
              }}
            >
              Delete
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  )
}

export default _CategoryEdit
