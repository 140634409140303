import React, { useState, useEffect } from 'react'
import { call } from '../Methods'

function TermsAndConditions () {
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(true)
  const contentKey = 'Page.termsandconditions'

  const getData = async () => {
    setLoading(true)
    const _termsAndConditionsPage = await call.content.getContents({
      fullName: contentKey
    })
    setContent(_termsAndConditionsPage)
    setLoading(false)
  }
  useEffect(() => {
    getData()
  }, [])

  if (loading) return <div className='loading'>Loading...</div>
  if (!content) return <div className='loading'>Something wrong happen...</div>
  return (
    <>
      {!loading && content && (
        <div className='page terms-and-conditions-page'>
          <div className='page-content text-content'>
            <div className='page-header'>
              <h1 className='main'>Terms And Conditions</h1>
            </div>
            <p
              className='paragraph'
              dangerouslySetInnerHTML={{ __html: content.value }}
            ></p>
          </div>
        </div>
      )}
      {loading && <div className='loading'>Loading...</div>}
    </>
  )
}

export default TermsAndConditions
