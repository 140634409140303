import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import '.././AdminApp.scss'
import { useSelector, useDispatch } from 'react-redux'

function _AdminSidebar () {

  const dashboardData = useSelector(state=>state.dashboard.dashboardData)

  return (
    <React.Fragment>
      <div className='sidebar'>
        <div className='logo'>
          <a href='/'>
            <img src='/logo_horizontal_woslogan.png' />
          </a>
        </div>
        <div className='menu'>
          <ul>
            <li>
              <Link to='/admin/dashboard'>Dashboard</Link>
            </li>
            <li>
              <Link to='/admin/items?pageNumber=1'>Items</Link>
              <ul className="submenu">
                <li><Link to='/admin/categories'>Categories</Link></li>
                <li><Link to='/admin/sizes'>Sizes</Link></li>
                <li><Link to='/admin/substrates'>Substrates</Link></li>
              </ul>
            </li>
            <li>
              <Link to='/admin/orders?pageNumber=1'>Orders {dashboardData.newOrders>0 && <span className="badge">{dashboardData.newOrders}</span>}</Link>
            </li>
            <li>
              <Link to='/admin/clientRequests?pageNumber=1'>Client Requests {dashboardData.newRequests>0 && <span className="badge">{dashboardData.newRequests}</span>}</Link>
            </li>
            <li>
              <Link to='/admin/partners'>Partners</Link>
            </li>
            <li>
              <Link to='/admin/users'>Users</Link>
            </li>
            <li>
              <Link to='/admin/contents'>Content</Link>
            </li>
          </ul>
        </div>
        <div className='footer'>
          <Link className='log-out' to='/logout'>
            Log out
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}

export default _AdminSidebar
