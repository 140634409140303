import React, { Fragment, useEffect, useRef, useState } from 'react'
import {  useLocation, useNavigate, Link, useParams } from 'react-router-dom'
import { use } from '../Helpers'
import { call } from '../Methods'
import { useDispatch,useSelector } from 'react-redux'
import { emptyCart } from '../Store/cartSlice'

export default function PaymentResult () {
  const {status} = useParams();
  let navigate = useNavigate()
  const dispatch = useDispatch();
  const userInfo = useSelector(state=>state.user.userInfo);
  const [seconds,setSeconds] = useState(5)
  let countDown;
  let _seconds;

  const countDownFunc = () =>{
    _seconds = seconds-1;
    setSeconds(_seconds);
  }

  const removeCartItems = () =>{
    if(status==="success"){
      dispatch(emptyCart())
    }
  }

  const redirectToHome = (e) => {
    console.log('timeout')
    clearInterval(countDown);
    navigate('/', {replace:true});
  }


 useEffect(()=>{
  removeCartItems();
  countDown = setInterval(countDownFunc,1000)

  setTimeout(redirectToHome, 7000);

 },[])



  return (
      <div className="page payment-result-page">
        {status==="success" &&
          <>
          <img src="/Icons/green-ok.svg"/>
          <p className='expression success'>Congratulations!</p>
          <p className='description'>Payment is successful</p>
          </>  
        }
        {status==="failed" &&
          <>
          <img src="/Icons/red-failed.svg"/>
          <p className='expression fail'>We are sorry :(</p>
          <p className='description'>Payment failed</p>
          </>
        }
        {!['failed','success'].includes(status) &&
          <>
          <p className="expression">404</p>
          </>
        }
        <p className="count-down">You will be redirected to homepage</p>
      </div>

    )
}
