import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Input from '../_Components/Input'
import { call } from '../../Methods'

function _PartnerAdd () {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const formRef = useRef()

  const saveData = async statusId => {
    setLoading(true)

    let uploaded_image = document.querySelector(
      'img[name="input-image-preview"]'
    )?.dataset['base64']

    let _partner = {}
    _partner.name = getInputValueByName('name')
    _partner.description = getInputValueByName('description')
    _partner.Image = { base64: uploaded_image, isNew: true }
    _partner.statusId = statusId

    //return;
    const _newPartner = await call.forLoggedUser.partner.addPartner(_partner)

    if (_newPartner.id) {
      formRef.current.reset()
      navigate(`/admin/partner/${_newPartner.id}`, { replace: true })
    }
    setLoading(false)
  }

  const getInputValueByName = name => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    if (element.length === 1) {
      return element[0].value || element[0].text
    } else if (element.length > 1) {
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  }

  useEffect(() => {}, [])

  return (
    <React.Fragment>
      <form className='form' ref={formRef}>
        <h4 className='form-title'>{'Add New Partner'}</h4>
        {loading && <div className='loader'>Loading...</div>}
        <div className='row'>
          <Input
            type={'text'}
            label={'Name'}
            fieldName={'name'}
            col={{ default: 12, md: 4 }}
            validate={function (value) {
              if (value == '0') {
                return false
              }
            }}
          />

          <Input
            type={'text'}
            label={'Description'}
            fieldName={'description'}
            col={{ default: 12, md: 4 }}
            value={null}
          />

          <Input
            type={'image'}
            label={'Image'}
            fieldName={'images'}
            multiple={false}
            col={{ default: 12, sm: 12, md: 12 }}
            value={null}
            data={[]}
          />
        </div>

        <div className='action-buttons'>
          <button
            type='button'
            className='back-button'
            onClick={function () {
              navigate('/admin/partners', { replace: true })
            }}
          >
            Back
          </button>

          <button
            type='button'
            className='save-button'
            onClick={() => saveData(0)}
          >
            Save as Draft
          </button>
          <button
            type='button'
            className='save-button'
            onClick={() => saveData(1)}
          >
            Save & Publish
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default _PartnerAdd
