import React, { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import Table from '../_Components/AdminTable.js'
import { useTable } from 'react-table'
import Variables from '../../global.js'
import { call } from '../../Methods.js'

function _ClientRequests () {
  const [tableData, setTableData] = useState()
  const [clientRequestStats, setClientRequestStats] = useState()


  const defaultParameters = { statusId:'', requestType:'', pageNumber: 1,pageSize: 10, sort: 'desc'}
  const userDefinedParameters = JSON.parse(localStorage.getItem('tpp_userSettings')).searchSettings;
  let _parameters = {};
  Object.assign(_parameters,defaultParameters,userDefinedParameters.searchSettings);
  const [parameters, setParameters] = useState(_parameters)

  let colorsByValues = {
    '-2': '#ff5252',
    '-1': '#fcba03',
    0: '#FF9800',
    1: '#4242c4',
    2: '#c442c4',
    3: '#42c442',
    4: '#c4c442',
    5: '#42c490',
    6: '#42c4c4',
    7: '#ff5252',
    8: '#fcba03',
    9: '#ff9800'
  }

  let tableColumns = [
    {
      title: 'Subject',
      property: ['requestType', 'requestTypeName'],
      type: 'label',
      mutate: values => {
        return (
          <span style={{ backgroundColor: colorsByValues[values[0]] }}>
            {values[1]}
          </span>
        )
      }
    },
    {
      title: 'Full Name',
      property: 'fullName'
    },
    {
      title: 'E-mail',
      property: 'email'
    },
    {
      title: 'Phone',
      property: 'phone'
    },
    {
      title: 'Receive Date',
      property: 'receiveDate',
      mutate:value=>{
        let _values = value.split('T');
        return `${_values[0]} ${_values[1].substring(0,5)}`;
      }
    },
    {
      title: 'Status',
      property: ['statusId', 'statusName'],
      type: 'label',
      mutate: values => {
        return (
          <span style={{ backgroundColor: colorsByValues[values[0]] }}>
            {values[1]}
          </span>
        )
      }
    },
    {
      title: 'Actions',
      type: 'actions-list',
      property: ['id', 'attachmentPath', 'email'],
      mutate: values => {
        return (
          <>
            <Link to={`/admin/clientrequests/${values[0]}`}>
              <img
                className='action-icon'
                title={'View'}
                src={'/Icons/view.png'}
              />
            </Link>

            {values[1] && (
              <Link
                onClick={e => {
                  e.preventDefault()
                  downloadFile(values[1], `Request #${values[0]} ${values[2]}`)
                }}
              >
                <img
                  className='action-icon'
                  title={'Download Attached File'}
                  src={'/Icons/filedownload.png'}
                />
              </Link>
            )}
          </>
        )
      }
    }
  ]

  const downloadFile = async (path, fileName) => {
    let extension = path.split('.')[1]
    await call.forLoggedUser.file.getFile(path, `${fileName}.${extension}`)
  }

  const getData = async (
    parameters
  ) => {
    const _clientRequests =
      await call.forLoggedUser.clientRequest.getClientRequests(
        parameters
      )
    if (_clientRequests.success) {
      setTableData(_clientRequests)
    }

    return _clientRequests
  }


  const headerContent = async () => {
    const _clientRequestStats =
      await call.forLoggedUser.clientRequest.getClientRequestStats()

    if (_clientRequestStats.length) {
      setClientRequestStats(_clientRequestStats)
      return _clientRequestStats
    }

    return []
  }

  useEffect(() => {
    headerContent()
  }, []);

  useEffect(()=>{
    getData(parameters);
  },[parameters])

  return (
    <React.Fragment>
      <h3 className='page-title'>Client Requests</h3>
      {tableData && tableData.data && <Table
          columns={tableColumns}
          headerContent={
            <>
              {clientRequestStats &&
                clientRequestStats
                  .sort((a, b) => b.notSeen - a.notSeen)
                  .map(row => {
                    return (
                      <Link key={row.requestType}>
                        <label
                          onClick={() => {
                            setParameters({...parameters,statusId:0,requestType:row.requestType})
                          }}
                          className='table-header-label'
                          style={{
                            backgroundColor: colorsByValues[row.requestType]
                          }}
                        >
                          {row.requestTypeName}
                          {row.notSeen > 0 && (
                            <span
                              className='badge'
                              style={{ color: colorsByValues[row.requestType] }}
                            >
                              {row.notSeen}
                            </span>
                          )}
                        </label>
                      </Link>
                    )
                  })}
            </>
          }
          loading={tableData?false:true}
          data={tableData.data}
          pagination={{
            enable: false,
            pageCount: 1,
            //settings: paginationSettings
          }}
        />}
    </React.Fragment>
  )
}

export default _ClientRequests
