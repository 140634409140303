import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userInfo: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {...initialState},
  reducers: {
    getUserInfo: (state,action)=>{
        
    },
    saveUser: (state, action) => { 
        state.userInfo = action.payload;
    },
    removeUser:(state) => {    
        state.userInfo = null;
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveUser,removeUser} = userSlice.actions

export default userSlice.reducer