import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Table from '../_Components/AdminTable.js'
import { useTable } from 'react-table'
import Variables from '../../global'
import { call } from './../../Methods.js'

function _Categories () {
  const [tableData, setTableData] = useState([])

  const defaultParameters = {
    parentId: '',
    type: '',
    enabled: '',
    statusId: ''
  }

  const userDefinedParameters = JSON.parse(
    localStorage.getItem('tpp_userSettings')
  ).searchSettings
  let _parameters = {}
  Object.assign(
    _parameters,
    defaultParameters,
    userDefinedParameters.searchSettings
  )
  
  const [parameters, setParameters] = useState(_parameters)

  let tableColumns = [
    {
      title: 'Name',
      property: 'name'
    },
    {
      title: 'Image',
      property: 'image.imageUrl',
      type: 'image',
      mutate: value => {
        return value
          ? `${Variables.base_url}/api/images/thumbnail-sm/${value}`
          : `/No_image_available.svg`
      }
    },
    {
      title: 'Enabled',
      property: 'enabled',
      type: 'label',
      mutate: value => {
        return value == 0 ? (
          <span className='label-red'>deactive</span>
        ) : (
          <span className='label-green'>active</span>
        )
      }
    },
    {
      title: 'Actions',
      property: 'id',
      type: 'actions-list',
      mutate: value => {
        return (
          <>
            <Link to={`/admin/category/${value}`}>
              <img
                className='action-icon'
                title={'Edit'}
                src={'/Icons/edit.png'}
              />
            </Link>
          </>
        )
      }
    }
  ]

  const getData = async () => {
    const _categories = await call.forLoggedUser.category.getCategories({});
    if(_categories.success){
      setTableData(_categories)
    }else{
      alert('Exception')
    }
    return _categories
  }

  useEffect(() => {
    getData()
  }, [])


  return (
    <React.Fragment>
      <h3 className='page-title'>Categories</h3>
      { tableData && tableData.data &&
        <Table
            columns={tableColumns}
            data={tableData.data}
            loading={tableData?false:true}
            pagination={{ enable: false }}
            addNew={{ enabled: true, url: '/admin/category/add' }}
        />
      }

    </React.Fragment>
  )
}

export default _Categories
