import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

function NotFound(){
    const navigate = useNavigate();

    return (
        <div className="not-found-page">
            <img src="/not-found.png"/>
            <h3>Page Not Found</h3>
            <p>The Page you are looking for does not exist or an other error occured.</p>
            <p>
                <Link onClick={()=>navigate(-1)}>Go Back</Link> or head over to <Link to={'/'}>theproprints.ca</Link> to choose a new direction.
            </p>
        </div>
    );
}

export default NotFound;