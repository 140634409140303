import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Input from '../_Components/Input'
import Variables from '../../global'
import { call } from '../../Methods'
import { useDispatch } from 'react-redux'
import { updateDashboardData } from '../../Store/dashboardSlice'

function _ClientRequestView () {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)
  const [clientRequest, setClientRequest] = useState()
  const [clientRequestStatuses, setClientRequestStatuses] = useState([])

  const loadData = async () => {
    setLoading(true)
    {
      let _clientRequest =
        await call.forLoggedUser.clientRequest.getClientRequest(id)

      if (_clientRequest.id) {
        setClientRequest(_clientRequest)
      } else {
        navigate('/admin/clientrequests', { replace: true })
      }

      if (_clientRequest.statusId == 0) {
        //status 0 is "received"
        _clientRequest =
          await call.forLoggedUser.clientRequest.updateClientRequestStatus({
            id: _clientRequest.id,
            statusId: 1
          }) //change status to "seen"
        setClientRequest(_clientRequest)
      }
    }
    {
      const _clientRequestStatuses =
        await call.forLoggedUser.clientRequest.getClientRequestStatuses()
      setClientRequestStatuses(
        _clientRequestStatuses.map(({ id, statusName }) => {
          return { value: id, text: statusName }
        })
      )
    }
    let dashboardInfo = await call.forLoggedUser.dashboard.getdashboardinfo();
    dispatch(updateDashboardData({_newRequests:dashboardInfo.newRequests}))
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const submitForm = async enabled => {
    setLoading(true)

    let _clientRequest = {}
    _clientRequest.id = +id
    _clientRequest.statusId = getInputValueByName('statusId')

    const _updateStatusResponse =
      await call.forLoggedUser.clientRequest.updateClientRequestStatus(
        _clientRequest
      )

    if (_updateStatusResponse.id) {
      setClientRequest(_updateStatusResponse)
      setLoading(false)
    }
  }

  const getInputValueByName = name => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    if (element.length === 1) {
      return element[0].value || element[0].text
    } else if (element.length > 1) {
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  }

  return (
    !loading && (
      <React.Fragment>
        <div className='client-request-content info'>
          <h4 className='form-title'>Client Request #{clientRequest.id}</h4>
          <div className='info-container'>
            <table>
              <tbody>
              <tr className="content-row">
                  <td className='subject-label'>Receive Date:</td>
                  <td>{clientRequest.receiveDate}</td>
                </tr>
                <tr className="content-row">
                  <td className='subject-label'>Subject:</td>
                  <td>{clientRequest.requestTypeName}</td>
                </tr>
                <tr className="content-row">
                  <td className='subject-label'>File: </td>
                  <td>
                  {clientRequest.attachmentPath ? (
                <span
                  className='attached-file'
                  onClick={async () => {
                    let extension = clientRequest.attachmentPath.split('.')[1]
                    await call.forLoggedUser.file.getFile(
                      clientRequest.attachmentPath,
                      `Request #${clientRequest.id} ${clientRequest.email}.${extension}`
                    )
                  }}
                >
                  Download Attached File By {clientRequest.fullName}
                </span>
              ) : (
                <span>No file</span>
              )}
                  </td>
                </tr>
                <tr className="content-row">
                  <td className='subject-label'>Message: </td>
                  <td>{clientRequest.message}</td>
                </tr>
                <tr className="content-row">
                  <td className='subject-label'>Full Name: </td>
                  <td>{clientRequest.fullName}</td>
                </tr>
                <tr className="content-row">
                  <td className='subject-label'>Phone: </td>
                  <td><Link to={`tel:${clientRequest.phone}`}>{clientRequest.phone}</Link></td>
                </tr>
                <tr className="content-row">
                  <td className='subject-label'>E-mail: </td>
                  <td><Link to={`mailto:${clientRequest.email}`}>{clientRequest.email}</Link></td>
                </tr>
              </tbody>
            </table>
          </div>
          {clientRequest && (
            <form className='form'>
              <Input
                type={'select'}
                fieldName={'statusId'}
                label={'Change Status To:'}
                data={clientRequestStatuses}
                col={{ default: 12, md: 4 }}
                value={clientRequest.statusId}
              />

              <div className='action-buttons'>
                <button
                  type='button'
                  className='back-button'
                  onClick={function () {
                    navigate('/admin/clientRequests', { replace: true })
                  }}
                >
                  Back
                </button>
                <button
                  type='button'
                  className='save-button'
                  onClick={function () {
                    submitForm()
                  }}
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </div>
      </React.Fragment>
    )
  )
}

export default _ClientRequestView
