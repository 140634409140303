import React, { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Table from '../_Components/AdminTable'
import Variables from '../../global'
import { call } from '../../Methods'
import { use } from '../../Helpers'

function _Items () {
  const [tableData, setTableData] = useState()
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading,setLoading] = useState(true);

  let tableColumns = [
    {
      title: 'Image',
      property: 'image',
      type: 'image',
      mutate: value => {
        return `${Variables.base_url}/api/images/thumbnail-sm/${value}`
      }
    },
    {
      title: 'Serial',
      property: 'itemNumber'
    },
    {
      title: 'Name',
      property: 'itemName'
    },
    {
      title: 'Status',
      property: 'statusName',
      type: 'label',
      mutate: value => {
        switch (value.toLowerCase()) {
          case 'published': {
            value = <span className='label-blue'>published</span>
            break
          }
          case 'draft': {
            value = <span className='label-green'>draft</span>
            break
          }
          default: {
            value = <span className='label-yellow'>{value}</span>
            break
          }
        }
        return value
      }
    },
    {
      title: 'Actions',
      type: 'actions-list',
      property: ['id'],
      mutate: value => {
        return (
          <>
            <Link to={`/admin/item/${value}`}>
              <img
                className='action-icon'
                title={'Edit'}
                src={'/Icons/edit.png'}
              />
            </Link>
          </>
        )
      }
    }
  ]

  const getData = async (parameters) => {
    console.log("ok " + parameters);
    setLoading(true);
    let _items = await call.forLoggedUser.item.getItems({});
    if(_items.success){
      setTableData(_items);
      setLoading(false);
    }
    return _items
  }

  useEffect(()=>{
    getData(use.userSettings());
  },[])

  return (
    <React.Fragment>
      <h3 className='page-title'>Items</h3>
      <Table
        columns={tableColumns}
        loading={loading}
        //data={tableData?.data}
        getMethod={getData}
        addNew={{ enabled: true, url: '/admin/item/add' }}
        pagination={{
          enable:true,
          settings:{
            pageNumber: 1, //+searchParams.get("pageNumber") || 1
            pageSize: 10, //+searchParams.get("pageSize") || 10
            sort: 'asc', //searchParams.get("sort") || 'asc'
            length: 0, //tableData?.length || 0
            pageCount: 0 //tableData?.pageCount || 0
          }
        }}
      />
    </React.Fragment>
  )
}

export default _Items
