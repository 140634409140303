import React, { useState } from "react";
import ItemCard from "./ItemCard";

function ItemsList({items}){

    return(
        <div className='row'>
        {
        items && items.map((item)=> {return <ItemCard key={item.id} item={item}/>})
        }
      </div>
    )
}

export default ItemsList;