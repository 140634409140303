import React, { useEffect, useState } from 'react'
import { call } from '../Methods'
import CategoryCard from '../Components/CategoryCard'
import WhyChooseUs from '../Components/WhyChooseUs'
import CustomerReviews from '../Components/CustomerReviews'
import { useLocation } from 'react-router-dom'
import Variables from '../global'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import PartnerCard from '../Components/PartnerCard'

function Home () {
  const [categories, setCategories] = useState([])
  const [partners, setPartners] = useState([])
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState(null)
  const location = useLocation()
  const contentNames = 'mainpageslider,mainpagebanner'

  const [sliderItems, setSliderItems] = useState([])
  const [customerReviews, setCustomerReviews] = useState([
    {image:'https://upload.wikimedia.org/wikipedia/commons/thumb/archive/4/43/20150912170242%21Paul_Circle.png/120px-Paul_Circle.png',stars:5,customerName:'Larry Johnson',reviewContent:`I've been using The Pro Prints Co. for my business's printing needs for over a year now, and I can't speak highly enough of their service and quality. From Signs to promotional roll up, every order has been nothing short of exceptional.`},
    {image:'https://upload.wikimedia.org/wikipedia/commons/thumb/archive/4/43/20150912170242%21Paul_Circle.png/120px-Paul_Circle.png',stars:5,customerName:'Larry Johnson',reviewContent:`First and foremost, the print quality is outstanding. The colors are vibrant, the text is crisp, and the images are reproduced with remarkable clarity. Whether it's glossy brochures or matte finish posters, Stellar Prints consistently delivers top-notch results that impress both me and my clients.`},
    {image:'https://upload.wikimedia.org/wikipedia/commons/thumb/archive/4/43/20150912170242%21Paul_Circle.png/120px-Paul_Circle.png',stars:5,customerName:'Larry Johnson',reviewContent:`First and foremost, the print quality is outstanding. The colors are vibrant, the text is crisp, and the images are reproduced with remarkable clarity. Whether it's glossy brochures or matte finish posters, Stellar Prints consistently delivers top-notch results that impress both me and my clients.`}
  ])

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 8000,
    lazyLoad: true,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <div
        style={{
          borderRadius: '10px',
          padding: '10px',
          bottom: '0px'
        }}
      >
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    )
  }

  window.scrollTo(0, 0)

  const getData = async () => {
    setLoading(true)
    const _categories = await call.category.getCategories({
      parentId: '',
      type: 1,
      enabled: 1
    })

    if (_categories.length) {
      setCategories(_categories)
    }

    const _partners = await call.partner.getPartners({})

    if (_partners.length) {
      setPartners(_partners)
    }

    const _contents = await call.content.getContents({
      names: contentNames
    })

    setSliderItems(
      [
        ...JSON.parse(_contents.find(c => c.name == 'mainpageslider').value)
      ].map((imageUrl, index) => (
        <div class='slider-item' key={index}>
          <img src={`${Variables.base_url}/api/images/original/${imageUrl}`} />
        </div>
      ))
    )

    setContent(_contents)
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  if (loading) return <div className='loading'>Loading...</div>
  return (
    <React.Fragment>
      <main className='page home-page'>
        <section className='slider'>
          {sliderItems && <Slider {...settings}>{sliderItems}</Slider>}
        </section>
        <div className='products-content page-section'>
          <div className='section-header'>
            <h2 className='main'>Product Categories</h2>
            <p className='sub'>
              These are tried and true favorites that will have you set to get
              down to business.
            </p>
          </div>

          <section className='categories row'>
            {!loading &&
              categories &&
              categories.map((category, index) => {
                return <CategoryCard key={category.id} category={category} />
              })}
          </section>
        </div>
        {!loading && partners && (
          <div className='products-content page-section'>
            <div className="container">
            <div className='section-header'>
              <h2 className='main'>Trusted by</h2>
              <p className='sub'>
                Sign Together, Shine Together: Elevate Your Brand with Our
                Trusted Partnerships!
              </p>
            </div>

            <section className='partners section-container row g-0 justify-content-center'>
              {partners.map((partner, index) => {
                return <PartnerCard key={partner.id} partner={partner} />
              })}
            </section>
            </div>

          </div>
        )}
        <WhyChooseUs />
        <CustomerReviews data={customerReviews}/>
      </main>
    </React.Fragment>
  )
}

export default Home
