import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Input from '../_Components/Input'
import { call } from '../../Methods'

function _CategoryAdd () {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [parentCategoriesList, setParentCategoriesList] = useState(null)

  const formRef = useRef()

  const [category, setCategory] = useState({
    id: undefined,
    parentId: undefined,
    name: undefined,
    imageUrl: undefined,
    description: undefined,
    statusId: undefined
  })

  const saveCategory = async () => {
    setLoading(true)

    let uploaded_image = document.querySelector(
      'img[name="input-image-preview"]'
    )?.dataset['base64']

    let _category = {}
    _category.parentId = getInputValueByName('parentId')
    _category.name = getInputValueByName('name')
    _category.description = getInputValueByName('description')
    _category.image = { base64: uploaded_image }
    _category.enabled = getInputValueByName('enabled')
    setCategory(_category)

    //return;
    const _newCategory = await call.forLoggedUser.category.createCategory(
      _category
    )

    if (_newCategory.success) {
      formRef.current.reset()
      setCategory(_newCategory.data)
      navigate(`/admin/category/${_newCategory.data.id}`, { replace: true })
    }
    setLoading(false)
  }

  const getDatasetValueByName = (name, dataKey) => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    return element.map(el => el.dataset[dataKey])
  }

  const getInputValueByName = name => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    if (element.length === 1) {
      return element[0].value || element[0].text
    } else if (element.length > 1) {
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  }

  const getData = async () => {
    setLoading(true);
    let _parentCategoriesList = await call.forLoggedUser.category.getCategories(
      { type: 1, enabled: 1 }
    )
    if (_parentCategoriesList.success) {
      _parentCategoriesList = [
        ...[{ value: '', text: 'Select Category' }],
        ..._parentCategoriesList.data.map(({ id, name }) => {
          return { value: id, text: name }
        })
      ]
      console.log('test',_parentCategoriesList);
      setParentCategoriesList(_parentCategoriesList)
    };
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <React.Fragment>
      <form className='form' ref={formRef}>
        <h4 className='form-title'>{'Add New Category'}</h4>
        {loading && <div className='loader'>Loading...</div>}
        <div className='row'>
          <Input
            type={'text'}
            label={'Name'}
            fieldName={'name'}
            col={{ default: 12, md: 4 }}
            validate={function (value) {
              if (value == '0') {
                return false
              }
            }}
          />

          {parentCategoriesList && <Input
            type={'select'}
            label={'Parent Category (optional)'}
            fieldName={'parentId'}
            col={{ default: 12, md: 4 }}
            data={parentCategoriesList}
            value={null}
          />}
          <Input
            type={'text'}
            label={'Description'}
            fieldName={'description'}
            col={{ default: 12, md: 4 }}
            value={null}
          />

          <Input
            type={'checkbox'}
            label={'Enabled'}
            fieldName={'enabled'}
            disabled={true}
            col={{ default: 12, md: 3 }}
            value={null}
          />

          <Input
            type={'image'}
            label={'Image'}
            fieldName={'images'}
            multiple={false}
            col={{ default: 12, sm: 12, md: 12 }}
            value={null}
            data={[]}
          />
        </div>

        <div className='action-buttons'>
          <button
            type='button'
            className='back-button'
            onClick={function () {
              navigate('/admin/sizes', { replace: true })
            }}
          >
            Back
          </button>
          <button
            type='button'
            className='save-button'
            onClick={function () {
              saveCategory(1)
            }}
          >
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default _CategoryAdd
