import React, { useState, useEffect } from 'react'
import { call } from '../Methods'

function ShippingPolicy () {
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(true)
  const contentKey = 'Page.shippingpolicy'

  const getData = async () => {
    setLoading(true)
    const _shippingPolicy = await call.content.getContents({
      fullName: contentKey
    })
    setContent(_shippingPolicy)
    setLoading(false)
  }
  useEffect(() => {
    getData()
  }, [])

  if (loading) return <div className='loading'>Loading...</div>
  if (!content) return <div className='loading'>Something wrong happen...</div>
  return (
    <>
      <div className='page shipping-policy-page'>
        <div className='page-content text-content'>
          <div className='page-header'>
            <h1 className='main'>Shipping Policy</h1>
          </div>
          <p className="paragraph" dangerouslySetInnerHTML={{ __html: content.value }}></p>
        </div>
      </div>
    </>
  )
}

export default ShippingPolicy
