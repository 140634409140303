import { createSlice } from '@reduxjs/toolkit'
import { call } from '../Methods';

//

const initialState = {
    dashboardData: {}
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateDashboardData: (state,action) => {
        state.dashboardData.newOrders = action.payload._newOrders!=undefined? action.payload._newOrders:state.dashboardData.newOrders ;
        state.dashboardData.newRequests = action.payload._newRequests!=undefined?action.payload._newRequests:state.dashboardData.newRequests;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateDashboardData} = dashboardSlice.actions

export default dashboardSlice.reducer