import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function CustomerReviews ({ data }) {
  var settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 1,
    dots: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 8000,
    lazyLoad: true,
    cssEase: 'linear',
    slidesToScroll: 1,
    appendDots: dots => (
      <div
        style={{
          borderRadius: '10px',
          padding: '10px',
          bottom: '0px'
        }}
      >
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className='customer-reviews page-section'>
        <div className='section-header'>
          <h2 className="main">Reviews by customers</h2>
          <p className='sub'>What our customers think about us?</p>
        </div>
        <section className='slider'>
          {data && (
            <Slider {...settings}>
              {data.map((review, index) => (
                <div key={index} className='container'>
                  <div class='slider-item customer-review' key={index}>
                    <img className="author-image" src={review.image} />
                    <div className="stars">{'★'.repeat(review.stars)}</div>
                    <p className="content">{review.reviewContent}</p>
                    <p className="author">- {review.customerName} -</p>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </section>
      </div>
    </React.Fragment>
  )
}

export default CustomerReviews
