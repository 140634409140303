import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Input from '../_Components/Input'
import { call } from '../../Methods'
import Variables from '../../global'
import { Editor } from '@tinymce/tinymce-react'
import { upload } from '@testing-library/user-event/dist/upload'

function _ContentEdit () {
  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState(null)
  const [input,setInput] = useState();

  const getData = async () => {
    setLoading(true)
    const _contentResponse = await call.forLoggedUser.content.getContents({ id: id })
  
    if (_contentResponse.success) {
      const _content = _contentResponse.data[0]; 
      setContent(_content);
      if (_content.type == 'image') {
        setInput(
          <Input
            type={'image'}
            label={'Image'}
            fieldName={'images'}
            col={{ default: 12, sm: 12, md: 12 }}
            value={_content.value}
            multiple={false}
            data={[
              new Object({
                src: `${Variables.base_url}/api/images/thumbnail-md/${_content.value}`,
                srclarge: `${Variables.base_url}/api/images/large/${_content.value}`,
                data: null,
                isNew: false,
                imageurl: _content.value
              })
            ]}
            eventHandler={{
              type: 'change',
              handler: function (e) {
                let target = e.target
              }
            }}
          />
        )
      } else if (_content.type == 'image_array') {
        let images = JSON.parse(_content.value).map(url => {
          return new Object({
            src: `${Variables.base_url}/api/images/thumbnail-md/${url}`,
            srclarge: `${Variables.base_url}/api/images/large/${url}`,
            data: null,
            isNew: false,
            imageurl: url
          })
        })
  
        setInput(
          <Input
            type={'image'}
            label={'Slide Images'}
            fieldName={'images'}
            col={{ default: 12, sm: 12, md: 12 }}
            value={_content.value}
            multiple={true}
            data={images}
            eventHandler={{
              type: 'change',
              handler: function (e) {
                let target = e.target
              }
            }}
          />
        )
      } else if (_content.type == 'textarea') {
        setInput(
          <Editor
            apiKey='gb1kc6yr42129ciscvhd6tavc911gr5u0mqc3w7gugsr6tzx'
            textareaName={'value'}
            init={{
              menubar: false,
              setup: function (editor) {
                editor.on('change', function () {
                    editor.save();
                });
            },
              toolbar:
                'undo redo | bold italic underline strikethrough | link | numlist bullist indent outdent | removeformat'
            }}
            initialValue={_content.value}
          />
        )
      } else {
        setInput(
          <Input
            type={_content.type}
            label={'Value'}
            fieldName={'value'}
            col={{ default: 12 }}
            value={_content.value}
          />
        )
      }
    } else {
      alert('Exception')
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, []);

  const saveContent = async () => {
    setContent(null)
    setLoading(true)
    let _content = {}
    _content.id = id

    _content.enabled = getInputValueByName('enabled')

    if (('image', 'image_array').includes(content.type)) {
      let image
      let images_array = []
      let uploaded_images = document.querySelectorAll(
        content.type == 'image'
          ? `img[name="input-image-preview"]`
          : `img[name="input-image-preview[]"]`
      )

      uploaded_images.forEach(ui => {
        image = new Object({
          base64: ui.dataset['base64'] || null,
          isNew: ui.dataset['isnew'] === 'true' ? true : false,
          imageUrl: ui.dataset['imageurl']
        })
        images_array.push(image)
      })

      if (content.type == 'image') {
        _content.image = images_array[0]
      } else {
        _content.images = images_array
      }
    } else {
      _content.value = getInputValueByName('value')
      console.log(getInputValueByName('value'))
    }

    setContent(_content)

    const _updateContentResponse =
      await call.forLoggedUser.content.updateContent(_content)
    if (_updateContentResponse.success) {
      navigate('/admin/contents',{replace:true})
    } else {
      alert('Exception')
    }

    setLoading(false)
  }

  const getInputValueByName = name => {
    let element = document.querySelectorAll(`form [name="${name}"]`);
    console.log(element);
    if (element.length === 1) {
      console.log(element[0].value)
      return element[0].value || element[0].text
    } else if (element.length > 1) {
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  }



  useEffect(() => {

    
  }, [])

  return (
    !loading &&
    content && input && (
      <React.Fragment>
        <form className='form'>
          <h4 className='form-title'>
            Edit: {content.groupName + '.' + content.name}
          </h4>
          <div className='row'>
            {input}
            <Input
              type={'checkbox'}
              label={'Enabled'}
              fieldName={'enabled'}
              col={{ default: 12 }}
              value={content.enabled}
              eventHandler={{
                type: 'change',
                handler: function (e) {
                  e.target.checked
                    ? setContent({ ...content, enabled: 1 })
                    : setContent({ ...content, enabled: 0 })
                }
              }}
            />
          </div>

          <div className='action-buttons'>
            <button
              type='button'
              className='back-button'
              onClick={function () {
                navigate('/admin/contents', { replace: true })
              }}
            >
              Back
            </button>
            <button
              type='button'
              className='save-button'
              onClick={function () {
                saveContent(1)
              }}
            >
              Save
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  )
}

export default _ContentEdit
