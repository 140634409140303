import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Table from '../_Components/AdminTable'
import { useTable } from 'react-table'
import Variables from '../../global'
import { call } from '../../Methods'

function _Sizes () {
  const [tableData, setTableData] = useState()

  const defaultParameters = {
    statusId: '',
    pageNumber: 1,
    pageSize: 10,
    sort: 'desc'
  }


  const userDefinedParameters = JSON.parse(
    localStorage.getItem('tpp_userSettings')
  ).searchSettings
  let _parameters = {}
  Object.assign(
    _parameters,
    defaultParameters,
    userDefinedParameters.searchSettings
  )
  const [parameters, setParameters] = useState(_parameters)

  let tableColumns = [
    {
      title: 'Id',
      property: 'id'
    },
    {
      title: 'Name',
      property: 'name'
    },
    {
      title: 'Description',
      property: 'description'
    },
    {
      title: (
        <React.Fragment>
          Enabled
          <span
            className='column-icon'
            title='Changes on this field will be considered only in new records'
          >
            &#9432;
          </span>
        </React.Fragment>
      ),
      property: 'enabled',
      type: 'checkbox'
    },
    {
      title: 'Actions',
      property: 'id',
      type: 'actions-list',
      mutate: value => {
        return (
          <>
            <Link to={`/admin/size/${value}`}>
              <img
                className='action-icon'
                title={'Edit'}
                src={'/Icons/edit.png'}
              />
            </Link>
          </>
        )
      }
    }
  ]

  const getData = async parameters => {
    const _sizes = await call.forLoggedUser.size.getSizes({})
    if(_sizes.success){
      setTableData(_sizes)
    }else{
      alert('Exception');
    }
    
    return _sizes
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <React.Fragment>
      <h3 className='page-title'>Sizes</h3>
      {tableData && <Table
        parameters={parameters}
        columns={tableColumns}
        loading={tableData?false:true}
        data={tableData.data}
        addNew={{ enabled: true, url: '/admin/size/add' }}
        pagination={{ enable: false }}
      />}
    </React.Fragment>
  )
}

export default _Sizes
