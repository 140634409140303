import React, { useEffect, useState } from 'react'
import {
  Link,
  useLocation,
  useNavigation,
  useSearchParams
} from 'react-router-dom'
import { call } from '../Methods'
import { useSelector } from 'react-redux'

function Sidebar ({ selected = null, show = null, clickHandler }) {
  const mobileView = useSelector(state => state.viewSettings.isMobile)
  const [searchParams, setSearchParams] = useSearchParams()
  const [categories, setCategories] = useState([])

  const loadCategories = async () => {
    const categoryList = await call.category.getCategories({
      parentId: '',
      type: '',
      enabled: 1
    })

    //categoryList.unshift({ id: 0, name: 'All Categories', selected: false })

    let _selectedCategory = +searchParams.get('category') || 0

    categoryList.forEach(c => (c.selected = _selectedCategory == c.id))

    setCategories(categoryList)
  }

  const changeCategory = (e, cId = 0) => {
    e.stopPropagation()
    setSearchParams(cId ? { category: cId, pageNumber: 1 } : { pageNumber: 1 })
    let _categories = categories
    _categories.forEach(c => {
      c.id == cId ? (c.selected = true) : (c.selected = false)
    })
    setCategories(_categories)
    //clickHandler()
  }

  const showSubCategories = (cId) => {
    let element = document.querySelector(`.category-link[data-category="${cId}"]`);
    if(element.classList.contains('open')){
      element.classList.toggle('open')
    }else{
      document.querySelectorAll('.category-link').forEach(l=>l.classList.remove('open'));
      element.classList.toggle('open')
    }
  }

  useEffect(() => {
    loadCategories()
  }, [])

  useEffect(() => {
    if (selected) {
      let _selected = selected.get('category') || 0
      let _categories = categories
      _categories.forEach(c => {
        c.id == _selected ? (c.selected = true) : (c.selected = false)
      })
      setCategories(_categories)
    }
  }, [selected])

  return (
    <React.Fragment>
      <div
        className={`categories-sidebar-main ${mobileView ? 'mobile' : ''} ${
          show ? 'open' : ''
        }`}
      >
        <ul className='main-category'>
          <li
            className={`category-link`}
            onClick={e => changeCategory(e, null)}
          >
            <span>
              <span>All categories</span>
            </span>
          </li>
          {categories &&
            categories
              .filter(c => !c.parentId)
              .map(c => {
                let sub = categories.filter(sc => sc.parentId == c.id)
                return (
                  <li
                    key={c.id}
                    className={`category-link`}
                    data-category={c.id}
                    onClick={()=>showSubCategories(c.id)
                    }
                  >
                    <span className={c.id == 0 ? ' all' : ''}>
                      <span>{c.name}</span>
                      {sub.length > 0 && <span className='has-sub'></span>}
                    </span>

                    {sub.length > 0 && (
                      <ul className='sub-categories'>
                        {mobileView && (
                          <li
                            key={c.id}
                            className={'category-link'}
                            onClick={e => changeCategory(e, c.id)}
                          >
                            <span>All items</span>
                          </li>
                        )}
                        {sub.map(sc => {
                          return (
                            <li
                              key={sc.id}
                              className={'category-link'}
                              onClick={e => changeCategory(e, sc.id)}
                            >
                              <span>{sc.name}</span>
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </li>
                )
              })}
        </ul>
      </div>
    </React.Fragment>
  )
}

export default Sidebar
